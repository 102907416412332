const appBaseUrl =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000'
    : `https://${window.location.host}`;

const APP_CONFIG = {
  baseWebApiUrl:
    window.location.hostname === 'localhost'
      ? ''
      : window.location.href.indexOf('staging') > -1
      ? 'https://little-union-41061-staging.azurewebsites.net'
      : 'https://app.cinebudgetpro.com',

  appBaseUrl,
  sessionVariables: {
    AUTH_TOKEN: 'authToken',
    USER_INFO: 'userInfo',
    OPEN_TABS: 'openTabs',
    SELECTED_TAB: 'selectedTab',
  },
  socialLoginClientId: {
    google:
      '408621052907-od86eur1h3mis2invbglrpsa0dokmhsp.apps.googleusercontent.com',
    apple: 'com.ios.cinebudget2',
    facebook: '990461205706658',
  },
  redirectURI: `${appBaseUrl}/auth/login`,
};

export default APP_CONFIG;
