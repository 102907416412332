import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useController } from 'react-hook-form';

import { ReactComponent as ErrorIcon } from 'assets/images/error-outline.svg';
import './styles.scss';

const FormSelect = React.forwardRef(
  (
    {
      error,
      label,
      placeholder,
      inputClassName,
      labelClassName,
      options,
      name,
      control,
      rules,
      isSearchable,
      creatable,
      isMulti, // Add isMulti prop
      ...rest
    },
    ref
  ) => {
    const { field } = useController({
      name,
      control,
      rules,
    });

    const customStyles = {
      control: (base, state) => ({
        ...base,

        borderColor: error
          ? '#dc3545'
          : state.isFocused
          ? '#86b7fe'
          : 'transparent',
        boxShadow:
          state.isFocused && error
            ? '0 0 0 0.25rem rgba(220,53,69, 0.25)'
            : state.isFocused
            ? '0 0 0 0.25rem rgba(13,110,253,.25)'
            : '',

        '&:hover': {
          borderColor: error
            ? '#dc3545'
            : state.isFocused
            ? '#86b7fe'
            : 'transparent',
        },
      }),
      menuPortal: base => ({ ...base, zIndex: 9999 }), // Ensure dropdown appears above other elements
      menu: base => ({
        ...base,
        maxHeight: '250px', // Ensure a max height for dropdown
        overflowY: 'auto', // Enable vertical scrolling
        zIndex: 9999, // Make sure it's visible in modals
      }),
      menuList: base => ({
        ...base,
        maxHeight: '200px', // Set max height for list
        overflowY: 'auto', // Enable scrolling
      }),
    };

    const controlComponent = ({ children, ...restProps }) => {
      return <components.Control {...restProps}>{children}</components.Control>;
    };

    const SelectComponent = creatable ? CreatableSelect : Select;

    return (
      <Form.Group className="form-select-group">
        {label && (
          <Form.Label
            className={`d-flex align-item-start w-100 form-select-group__label ${labelClassName}`}
          >
            {label}
          </Form.Label>
        )}
        <SelectComponent
          placeholder={placeholder}
          styles={customStyles}
          // components={{
          //   Control: controlComponent,
          //   IndicatorSeparator: null,
          // }}
          ref={ref}
          className={`select-dropdown ${inputClassName}`}
          classNamePrefix="select-dropdown"
          options={options}
          isSearchable={isSearchable}
          isMulti={isMulti} // Enable multi-select
          {...field}
          value={
            typeof field?.value === 'string'
              ? options?.find(item => item?.value === field?.value)
              : field?.value
          }
          {...rest}
        />
        {error && (
          <Form.Control.Feedback type="invalid">
            <span className="error-icon">
              <ErrorIcon />
            </span>
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    );
  }
);

FormSelect.displayName = 'FormSelect';

FormSelect.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  rules: PropTypes.object.isRequired,
  isSearchable: PropTypes.bool,
  creatable: PropTypes.bool,
  isMulti: PropTypes.bool, // Add isMulti prop type
};

FormSelect.defaultProps = {
  error: '',
  labelClassName: '',
  inputClassName: '',
  placeholder: '',
  isSearchable: false,
  creatable: false,
  isMulti: false, // Add isMulti default prop
};

export default FormSelect;
