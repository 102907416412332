/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import GridLoader from 'components/GridLoader';
import { ReactComponent as Selector } from 'assets/images/selector.svg';
import { ReactComponent as HighlightedSelector } from 'assets/images/highlighted-selector.svg';
import { ReactComponent as FolderIcon } from 'assets/images/folder-icon.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross-icon.svg';
import { ReactComponent as OpenInNew } from 'assets/images/open-in-new.svg';
import { ReactComponent as Download } from 'assets/images/download.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';
import { ReactComponent as Share } from 'assets/images/share.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { ReactComponent as MoveIcon } from 'assets/images/move-icon.svg';
import { ReactComponent as MoveIcon2 } from 'assets/images/move-icon2.svg';
import { ReactComponent as GreaterArrow } from 'assets/images/greater-icon.svg';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import MoveBudgets from '../../components/MoveBudgets';
import { toast } from 'react-toastify';
import { useAppContext } from 'contexts/AppContext';
import { useFringeMutation } from 'utils/api/mutations';
import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow-back.svg';
import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/images/arrow-forward.svg';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';
import { useFringeQuery, useTabsQueries } from 'utils/api/queries';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import AddFolder from 'components/AddFolder';
import EditFolder from 'components/EditFolder';
import CreateBudgetInsideFolder from 'components/CreateBudgetInsideFolder';
import EditFringe from 'components/EditFringe';
import DeleteFolder from 'components/DeleteFolder';
import CreateFringe from 'components/CreateFringe';
import DeleteMultiFringe from 'components/DeleteFringe';
import { set } from 'react-hook-form';
import Chip from 'components/Chip';
import { formatCurrency } from 'utils/helper';

const FringesDetails = ({ onFringeCreated }) => {
  // Contexts and Hooks
  const { createTabsMutation } = useHomeScreenContext();
  const { userActivityMutation } = useAppContext();
  const { useCreateFringeMutation } = useFringeMutation;
  const createFringeMutation = useCreateFringeMutation();

  // State Variables

  const [searchTxt, setSearchTxt] = useState('');
  const [showMovePopup, setShowMovePopup] = useState(false);
  const [shouldCallApi, setShouldCallApi] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showNoAPi, setNoAPi] = useState(false);
  const [advSearch, setAdvsearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [parentId, setparentId] = useState(null);
  const [parentNames, setparentNames] = useState([]);
  const [products, setProducts] = useState([]);
  const [parentDetails, setParentDetails] = useState([]);
  const [showBudgetIds, setBudgetIds] = useState([]);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showEditFolder, setShowEditFolder] = useState(false);
  const [folderName, setFolderName] = useState('');

  const [fringeId, setfringeId] = useState('');
  const [fringeName, setfringeName] = useState('');
  const [fringeAbbreviation, setfringeAbbreviation] = useState('');
  const [fringeDisplayName, setfringeDisplayName] = useState('');
  const [fringeDescription, setfringeDescription] = useState('');
  const [fringeType, setfringeType] = useState('');
  const [fringeValue, setfringeValue] = useState('');
  const [fringeCap, setfringeCap] = useState('');
  const [fringeBaseCap, setfringeBaseCap] = useState('');
  const [fringeCapOnTotal, setfringeCapOnTotal] = useState('');
  const [fringeCapValue, setfringeCapValue] = useState('');
  const [fringeGroup, setfringeGroup] = useState('');

  const [folderId, setFolderId] = useState('');
  const [budgetId, setbudgetId] = useState(null);
  const [showCreateFringeModal, setShowCreateFringeModal] = useState(false);
  const [showEditBudgetFolder, setShowEditBudgetFolder] = useState(false);
  const [BudgetTypeData, setBudgetType] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const productsRef = useRef(products);
  const searchBarRef = useRef();
  const contextualSuggestionsRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Fringes Data Fetching
  const {
    data: budget_details,
    isLoading,
    refetch,
  } = useFringeQuery.useFringeDetailsQuery({
    enabled: shouldCallApi,
  });
  const budgetData = budget_details?.data;

  // Tabs Data Fetching
  const { data: tabsDetails } = useTabsQueries.useOpenTabsQuery({
    enabled: true,
  });
  const tabsData = tabsDetails?.data;

  // Effect to Transform and Set Products Data
  useEffect(() => {
    if (!isLoading && budgetData?.data) {
      const newProducts = budgetData.data.map(budget => ({
        id: budget.id,
        full_name: budget.name,
        type: budget.type,
        display_name: budget.display_name,
        description: budget.description,
        abbreviation: budget.abbreviation,
        value: parseFloat(budget.value), // Convert string value to a float
        base_cap: parseFloat(budget.base_cap), // Convert string cap_value to a float
        cap_on_total: parseFloat(budget.cap_on_total), // Use dash if no value
        groups: budget.group?.join(', ') || '', // Flatten the group array into a comma-separated string
        fringe_total: budget.fringe_total,
      }));
      setProducts(newProducts); // Update state with transformed data
      setLoading(false); // End loading state
    }
  }, [isLoading, budgetData]);

  // Context Functions from HomeScreenContext
  const {
    setSeletedDelFringe,
    budgetList,
    showShareBudgetModal,
    selectedBudget,
    showCreateFolderModal,
    setSelectedDeldata,
    setFolderSelectedDeldata,
    showDeleteMultiBudgets,
    showDeleteFolder,
    showCreateBudgetModal,
    makeAciveTabMutation,
    getBudgetType,
    selectTab,
    openTabsList,
    setOpenTabsList,
    hasBudgetEditAccessInList,
    setBudgetToRename,
    showDeleteFringeModal,
    hideDeleteFringeModal,
    setFringeSelectedDeldata,
    showDeleteFringe,
    contextualSuggestions,
    addedContextualSuggestions,
    setAddedContextualSuggestions,
  } = useHomeScreenContext();

  const handleFringeCreated = () => {
    console.log('Fringe created');
    // setFringeCreated(!fringeCreated); // Toggle the state to trigger useEffect
    onFringeCreated();
  };

  // Handle Creating Tabs
  const handleCreateTabs = async selRowsData => {
    const payload = selRowsData.map(row => ({
      budget: row.id,
    }));

    try {
      const response = await createTabsMutation.mutateAsync(payload);
      const newTabs = response?.data;
      await makeAciveTabMutation.mutateAsync(newTabs[0]?.id);
      setOpenTabsList([...(tabsData || []), ...(newTabs || [])]);

      window.location.href = '/home';
    } catch (error) {
      console.error('Error in tabs creation', error);
      toast.error('Failed to create tabs');
    }
  };

  // Handle Checkbox Selection

  const handleSelectRow = (event, row) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedRows(prevSelected => {
        const updatedSelected = [...prevSelected, row.original];
        console.log('After adding:', updatedSelected);
        return updatedSelected;
      });
    } else {
      setSelectedRows(prevSelected => {
        const updatedSelected = prevSelected.filter(
          selectedRow => selectedRow.id !== row.original.id
        );
        console.log('After removing:', updatedSelected);
        return updatedSelected;
      });
    }
  };

  // Handle Folder Click
  const handleFolderClick = (type, data) => {
    let obj = {};
    if (type === 'Folder') {
      obj = {
        parent_id: data?.parent_id,
        folder_id: data?.id,
        type: data?.type,
      };
    } else {
      obj = {
        parent_id: data?.folder,
        folder_id: data?.id,
        type: data?.type,
      };
    }
    setParentDetails(prevDetails => [...prevDetails, obj]);
  };

  // Get Random Color (Unused in Current Implementation)
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Handle Modal Closure
  const closeFolder = () => {
    setShowCreateFringeModal(false);
    setShowMovePopup(false);
    setShowEditFolder(false);
    setShowEditBudgetFolder(false);
    setLoading(false);
    refetch(); // Refetch fringes data after closing any folder-related modal
  };

  // Handle Move Popup Closure
  const handleMovePopupClose = () => {
    setShowMovePopup(false);
    // setSelectedRows([]);
    setparentNames([]);
    setparentId(null);
    setSearchQuery('');
    setAdvsearch(false);
    refetch(); // Refetch fringes data after moving
  };

  // Handle Search Input Change
  const handleSearchChange = event => {
    const value = event?.target?.value;
    setSearchTxt(value);
  };

  // Handle Clearing Search
  const handleClearSearch = () => {
    setSearchTxt('');
    setSearchQuery('');
  };

  // Handle Collaborator Class (Unused in Current Implementation)
  const handleCollaboratorCls = (collaborator, index, row) => {
    if (row?.original?.type === 'Folder') {
      return;
    }
    if (index === 0) {
      if (collaborator?.profileImage) {
        return 'first-collaborator-img';
      } else {
        return 'first-collaborator';
      }
    } else {
      return 'user-pic-wrapepr';
    }
  };

  // Handle Key Press in Search Bar
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      const filteredList =
        searchTxt?.length &&
        budgetList?.filter(budget =>
          budget.name?.toLowerCase().includes(searchTxt?.toLowerCase())
        );

      // eslint-disable-next-line no-undef
      setFilteredBudgets(filteredList || []);
      setSearchQuery(searchTxt);
    }
    setparentNames([]);
    setparentId(null);
  };

  // Handle Sharing a Fringe
  const handleShare = (id, dataTemp) => {
    selectTab(id, dataTemp);
    showShareBudgetModal();
  };

  // Handle Dropdown Toggle
  const handleShowDropdown = event => {
    setShowDropdown(!showDropdown);
    setAnchorEl(event.currentTarget);
  };

  // Handle Result Click (Unused in Current Implementation)
  const handleResultClick = budget => () => {
    // selectTab(budget?.id, budget);
    // eslint-disable-next-line no-undef
    setFilteredBudgets([]);
  };

  // Handle Create Fringe Modal
  const handleCreateFringe = () => {
    setShowCreateFringeModal(true);
  };

  // Handle Move Budgets Modal
  const handleMoveBudgets = () => {
    setShowMovePopup(true);
  };

  // Handle Checkbox Change for Advanced Search
  const handleCheckboxChange = e => {
    setAdvsearch(e?.target?.checked);
    setparentNames([]);
    setparentId(null);
  };

  // Custom Sort Function for Case-Insensitive Sorting
  const caseInsensitiveSort = (rowA, rowB, columnId, desc) => {
    const a = rowA.values[columnId]?.toString().toLowerCase();
    const b = rowB.values[columnId]?.toString().toLowerCase();

    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };
  // Effect to Keep Products Ref Updated
  useEffect(() => {
    // Update the ref value whenever the products state changes
    productsRef.current = products;
  }, [products]);

  // Effect to Reset Selected Rows When Data Changes
  useEffect(() => {
    console.log('Products changed, resetting selectedRows');
    setSelectedRows([]);
  }, [products]);

  // Effect to Reset Selected Rows When Data Changes
  useEffect(() => {
    setSelectedRows([]);
  }, [products]);

  // Ensure selectedRows is not reset unnecessarily
  useEffect(() => {
    console.log('Selected Rows:', selectedRows);
  }, [selectedRows]);

  // Columns Definition for React Table

  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <span
              className="header-txt"
              style={{ marginRight: '5px', marginLeft: '50px' }}
            >
              Full Fringe Name
            </span>
            {/* <Search /> */}
          </>
        ),
        accessor: 'full_name',
        sticky: 'left',
        Cell: ({ row }) => (
          <div
            className={
              row?.original?.type === 'Folder'
                ? 'uname-cell-fringe-group cursorpointer'
                : 'uname-cell-fringe-group cursorpointer'
            }
          >
            <input
              type="checkbox"
              checked={selectedRows.some(
                selectedRow => selectedRow.id === row.original.id
              )}
              style={{
                cursor: 'pointer',
                width: '16px',
                height: '16px',
                margin: '0px 10px 0px 5px',
              }}
              onChange={event => {
                console.log('Checkbox changed:', event.target.checked);
                console.log('Row ID:', row.original.id);
                handleSelectRow(event, row);
              }}
              className="custom-checkbox"
            />
            <div
              onClick={event => {
                console.log('Row ID:', row.original.id);
                selectTab(row?.original?.id, row?.original);
                handleCreateTabs([row?.original]);
              }}
            />
            &nbsp;
            <span
              style={{ marginRight: '10px' }}
              onClick={() => {
                handleFolderClick(row?.original?.type, row?.original);
                if (row?.original?.type === 'Folder') {
                  // setparentNames(prevParents => [
                  //   ...prevParents,
                  //   {
                  //     name: row?.original?.full_name,
                  //     parent_id: row?.original?.id,
                  //     id: row?.original?.parent_id,
                  //   },
                  // ]);
                  setparentNames(prevParents => {
                    // Check if the id already exists in the array
                    const idExists = prevParents.some(
                      parent => parent.id === row?.original?.parent_id
                    );

                    if (!idExists) {
                      // If the id doesn't exist, append the new object
                      return [
                        ...prevParents,
                        {
                          name: row?.original?.full_name,
                          parent_id: row?.original?.id,
                          id: row?.original?.parent_id,
                        },
                      ];
                    } else {
                      // If the id already exists, you can choose to update the existing object or ignore the new one
                      // For example, you might want to update the name property of the existing object:
                      return prevParents.map(parent => {
                        if (parent.id === row?.original?.parent_id) {
                          return {
                            ...parent,
                            name: row?.original?.full_name,
                          };
                        }
                        return parent;
                      });
                      // Alternatively, you can just return the existing state without any changes
                      // return prevParents;
                    }
                  });

                  setparentId(row?.original?.id);
                } else {
                  selectTab(row?.original?.id, row?.original);
                  // handleCreateTabs([row?.original]);
                  // setOpenTabsList([row?.original]);                     setfringeId(row?.original?.id);
                  setfringeName(row?.original?.full_name);
                  setfringeAbbreviation(row?.original?.abbreviation);
                  setfringeDisplayName(row?.original?.display_name);
                  setfringeDescription(row?.original?.description);
                  setfringeType(row?.original?.type);
                  setfringeValue(row?.original?.value);
                  setfringeBaseCap(row?.original?.base_cap);
                  setfringeCapOnTotal(row?.original?.cap_on_total);
                  setfringeGroup(row?.original?.groups);

                  console.log('row', row);
                  // setparentId(row?.original?.id);
                  setShowEditBudgetFolder(true);
                  selectTab(row?.original?.id, row?.original);
                  setBudgetType(row?.original?.template_type);
                  // window.location.href = '/home';
                }
              }}
            >
              {row.original.full_name}{' '}
            </span>
          </div>
        ),
        sortType: 'caseInsensitiveSort',
        disableSortBy: false,
      },
      {
        Header: 'Display Name',
        accessor: 'display_name',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ value }) => <div style={{ marginLeft: '2px' }}>{value}</div>,
      },
      {
        Header: 'Abbreviation',
        accessor: 'abbreviation',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ value }) => <div style={{ marginLeft: '2px' }}>{value}</div>,
      },
      {
        Header: 'Type',
        accessor: 'type',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ value }) => <div style={{ marginLeft: '2px' }}>{value}</div>,
      },
      {
        Header: 'Value',
        accessor: 'value',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ row }) => (
          <div style={{ marginLeft: '2px' }}>
            {row.original.type === 'flat fee'
              ? formatCurrency(row.original.value)
              : row.original.value + '%'}
          </div>
        ),
      },
      {
        Header: 'Base Cap',
        accessor: 'base_cap',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div style={{ marginLeft: '2px' }}>
            {row.original.base_cap || '-'}
          </div>
        ),
      },
      {
        Header: 'Cap On Total',
        accessor: 'cap_on_total',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div style={{ marginLeft: '2px' }}>
            {row.original.cap_on_total || '-'}
          </div>
        ),
      },
      {
        Header: 'Group',
        accessor: 'groups',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ row }) => {
          const groups = row.original.groups.split(', ');
          return (
            <div style={{ marginLeft: '2px' }}>
              {groups.length > 1
                ? `${groups[0]} +${groups.length - 1}`
                : groups[0] || '-'}
            </div>
          );
        },
      },
      {
        Header: 'Fringe Total',
        accessor: 'fringe_total',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div style={{ marginLeft: '2px' }}>
            {row.original.fringe_total || '-'}
          </div>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="folder-actions-wrapper cursorpointer">
            <>
              {row?.original?.type === 'Folder' ? (
                <>
                  <MoveIcon2
                    title="Move"
                    className="move-icon"
                    onClick={() => {
                      setSelectedRows([row?.original]);
                      setShowMovePopup(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <Edit
                    onClick={() => {
                      setfringeId(row?.original?.id);
                      setfringeName(row?.original?.full_name);
                      setfringeAbbreviation(row?.original?.abbreviation);
                      setfringeDisplayName(row?.original?.display_name);
                      setfringeDescription(row?.original?.description);
                      setfringeType(row?.original?.type);
                      setfringeValue(row?.original?.value);
                      setfringeBaseCap(row?.original?.base_cap);
                      setfringeCapOnTotal(row?.original?.cap_on_total);
                      setfringeGroup(row?.original?.groups);

                      console.log('row', row);
                      // setparentId(row?.original?.id);
                      setShowEditBudgetFolder(true);
                      selectTab(row?.original?.id, row?.original);
                      setBudgetType(row?.original?.template_type);
                      // setBudgetToRename(row?.original);
                      // showCreateBudgetModal();
                    }}
                  />
                  <Delete
                    title="Delete"
                    onClick={() => {
                      console.log('row', row);
                      setSeletedDelFringe([row?.original]);
                    }}
                    // onFringeCreated={handleFringeCreated}
                  />
                </>
              )}
            </>
          </div>
        ),
      },
    ],
    [selectedRows] // Ensure columns are updated when selectedRows changes
  );

  console.log('Columns:', columns);

  // React Table Instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: products,
        sortTypes: {
          caseInsensitiveSort: (rowA, rowB, columnId) => {
            const a = rowA.values[columnId]?.toString().toLowerCase();
            const b = rowB.values[columnId]?.toString().toLowerCase();

            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
          },
        },
        initialState: { pageIndex: 0 },
        disableSortRemove: true,
      },
      useGlobalFilter,
      useSortBy,
      useRowSelect
    );

  return (
    <>
      <div className="budget-details-wrapper mt-3">
        {/* Header Section */}
        <div className="details-header-wrapper">
          <div className="details-left d-flex align-items-center">
            <div className="detail-header">Budget Fringes</div>
          </div>
          <div className="details-right d-flex align-items-center">
            <button
              label="Add New Fringe"
              type="button"
              className="new-folder"
              onClick={handleCreateFringe}
            >
              {/* <NewBudget /> */}
              Add New Fringe
            </button>
          </div>
        </div>
        {/* Loading Indicator */}
        {loading ? (
          <GridLoader />
        ) : (
          <>
            {/* Breadcrumb Navigation */}
            {parentNames?.length > 0 && (
              <div className="folders-path">
                <div
                  className="bud-hed cursorpointer"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  My Budgets
                </div>
                <GreaterArrow />
                {parentNames?.map((item, index) => (
                  <div className="folder-data" key={item}>
                    <FolderIcon />
                    <div
                      className="bud-head cursorpointer"
                      onClick={() => {
                        if (item?.parent_id === null) {
                          window?.location?.reload();
                        } else {
                          setparentId(item?.parent_id);
                          const indexOfParent15 = parentNames.findIndex(
                            parentData =>
                              parentData?.parent_id === item?.parent_id
                          );
                          const newArray =
                            indexOfParent15 !== -1
                              ? parentNames.slice(0, indexOfParent15 + 1)
                              : parentNames;
                          setparentNames(newArray);
                          setShouldCallApi(true);
                        }
                      }}
                    >
                      {item?.name}
                    </div>
                    {index < parentNames.length - 1 && <GreaterArrow />}
                  </div>
                ))}
              </div>
            )}

            {/* Selected Rows Actions */}
            {selectedRows?.length > 0 && (
              <div className="selected-records-wrapper">
                <div className="sel-rec-item-count">
                  <CrossIcon onClick={() => setSelectedRows([])} />
                  {selectedRows?.length}{' '}
                  {selectedRows?.length === 1 ? 'item' : 'items'} selected
                </div>
                <div
                  style={{
                    marginRight: 'auto',
                    marginLeft: '20px',
                    color: 'black',
                    fontWeight: '1000',
                    fontSize: '1.2em', // Increase size for a bolder look
                  }}
                >
                  Total Amount of Selected Fringes:$0.00
                </div>
                <div className="actions-wrapper">
                  <Delete
                    title="Delete"
                    onClick={() => setSeletedDelFringe(selectedRows)}
                    // onFringeCreated={handleFringeCreated}
                  />
                </div>
              </div>
            )}

            {/* Table Section */}
            <div className="admin-users-body">
              <div
                className={
                  selectedRows?.length > 0
                    ? 'table-container-fringe-groups table-new-height mt-3'
                    : 'table-container-fringe-groups mt-3'
                }
              >
                <table {...getTableProps()} className="table-cls w-100">
                  {/* Table Header */}
                  <thead className="app-users-table-head">
                    {headerGroups?.map(headerGroup => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={headerGroup.id}
                      >
                        {headerGroup.headers?.map(column => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={column.id}
                          >
                            {column.render('Header')}
                            <span>
                              &nbsp;
                              {!column.disableSortBy &&
                                (column.isSorted ? (
                                  <span className="sorted-icon-highlight">
                                    <HighlightedSelector />
                                  </span>
                                ) : (
                                  <Selector />
                                ))}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {/* Table Body */}
                  {rows && products?.length > 0 ? (
                    <tbody {...getTableBodyProps()} className="table-scroll">
                      {rows.map(row => {
                        prepareRow(row);
                        const isSelected = selectedRows.some(
                          selectedRow => selectedRow.id === row.original.id
                        );
                        return (
                          <tr
                            {...row.getRowProps()}
                            className={`table-body-row ${
                              isSelected ? 'selected-row' : ''
                            }`}
                            key={row.id}
                          >
                            {row.cells.map((cell, cellIndex) => (
                              <td
                                {...cell.getCellProps()}
                                key={cellIndex}
                                style={{
                                  width: cellIndex === 0 ? '20%' : 'auto',
                                  paddingLeft: cellIndex === 0 ? '50px' : '0',
                                }}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={columns.length}>
                          <div className="no-budget-found">
                            <div className="no-found-head">Create a Fringe</div>
                            <div className="no-found-body">
                              You Do Not Have Any Fringes At The Moment For This
                              Budget
                            </div>
                            <button
                              label="New Budget"
                              type="button"
                              className="new-folder"
                              onClick={() => setShowCreateFringeModal(true)}
                            >
                              Add New Fringe
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Conditional Rendering of Modals */}
      {showDeleteFringe && (
        <DeleteMultiFringe
          onFringeCreated={handleFringeCreated}
          refetch={refetch}
        />
      )}
      {showDeleteFolder && <DeleteFolder refetch={refetch} />}
      {showAddFolder && (
        <AddFolder
          parentid={parentId}
          showAddFolder={showAddFolder}
          closeFolder={closeFolder}
          refetch={refetch}
        />
      )}
      {showMovePopup && (
        <MoveBudgets
          showMovePopup={showMovePopup}
          onMoveClose={handleMovePopupClose}
          selBudgets={selectedRows}
          refetch={refetch}
        />
      )}
      {showEditFolder && (
        <EditFolder
          refetch={refetch}
          parentid={parentId}
          showEditFolder={showEditFolder}
          closeFolder={closeFolder}
          folderName={folderName}
          folderId={folderId}
        />
      )}
      {showCreateFringeModal && (
        <CreateFringe
          closeFolder={closeFolder}
          showCreateFringeModal={showCreateFringeModal}
          refetch={refetch}
          onFringeCreated={handleFringeCreated}
        />
      )}
      {showEditBudgetFolder && (
        <EditFringe
          fringeId={fringeId}
          frineName={fringeName}
          fringeAbbreviation={fringeAbbreviation}
          fringeDisplay={fringeDisplayName}
          fringeDescription={fringeDescription}
          fringeType={fringeType}
          fringeValue={fringeValue}
          fringeBaseCap={fringeBaseCap}
          fringeCapOnTotal={fringeCapOnTotal}
          fringeGroup={fringeGroup}
          showCreateFringeModal={showEditBudgetFolder}
          closeFolder={closeFolder}
          refetch={refetch}
        />
      )}
    </>
  );
};

// PropTypes Validation
FringesDetails.propTypes = {
  onFringeCreated: PropTypes.func.isRequired,
  budgetList: PropTypes.array,
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.number,
      full_name: PropTypes.string,
      // ... add other properties as needed
    }),
  }),
};

export default FringesDetails;
