import React from 'react';
import PropTypes, { element } from 'prop-types';

import './styles.scss';

const Chip = ({ className, title, icon, onClick, disabled, fringe }) => {
  return (
    <div
      className={`app-chip ${
        disabled ? 'app-chip--disabled' : ''
      } ${className}`}
      onClick={disabled ? undefined : onClick}
    >
      <div>{title}</div>
      {fringe}

      {icon}
    </div>
  );
};

Chip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fringe: PropTypes.string.isRequired,
};

Chip.defaultProps = {
  className: '',
  icon: <></>,
  onClick: () => null,
  disabled: null,
};

export default Chip;
