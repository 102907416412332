import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from './endpoints';

export const useBudgetQueries = {
  useBudgetQuery: ({ config = {}, enabled }) => {
    return useQuery(['get-budget'], () => API_ENDPOINTS.budgets.get_budgets(), {
      retry: false,
      enabled,
      ...config,
    });
  },

  useFolderBudgetQuery: ({ config = {}, enabled }) => {
    return useQuery(['get-folder'], () => API_ENDPOINTS.budgets.get_folders(), {
      retry: false,
      enabled,
      ...config,
    });
  },

  useFolderByIdQuery: ({ config = {}, enabled, folderId }) => {
    return useQuery(
      ['get-folder-by-id', { folderId }],
      () => API_ENDPOINTS.budgets.get_folder_by_id(folderId),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  useBudgetByIdQuery: ({ config = {}, enabled, budgetId }) => {
    return useQuery(
      ['get-budget-by-id', { budgetId }],
      () => API_ENDPOINTS.budgets.get_budget_by_id(budgetId),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  useBudgetTypeQuery: ({ config = {}, enabled }) => {
    return useQuery(
      ['get-budget-type'],
      () => API_ENDPOINTS.budgets.get_budget_types(),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  useSwimlaneQuery: ({ config = {}, enabled, payload }) => {
    return useQuery(
      ['get-swimlanes-in-budget', { ...payload }],
      () => {
        if (payload.type === 'swimlane')
          return API_ENDPOINTS.budgets.get_swimlanes(payload.id);

        if (payload.type === 'line-item')
          return API_ENDPOINTS.budgets.get_line_items(payload.id);

        if (payload.type === 'line-item-children')
          return API_ENDPOINTS.budgets.get_line_item_children(payload.id);
      },
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  useSwimlaneDetailsQuery: ({ config = {}, enabled, payload }) => {
    return useQuery(
      ['get-swimlanes-details', { ...payload }],
      () => {
        if (payload.type === 'swimlane')
          return API_ENDPOINTS.budgets.get_swimlane_detailed_sheet(payload.id);

        if (payload.type === 'line-item')
          return API_ENDPOINTS.budgets.get_line_item_detailed_sheet(payload.id);

        if (payload.type === 'line-item-children')
          return API_ENDPOINTS.budgets.get_line_item_children_detailed_sheet(
            payload.id
          );
      },
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  useContextualSuggestionsQuery: ({ config = {}, enabled }) => {
    return useQuery(
      ['get_contextual_suggestions'],
      () => API_ENDPOINTS.contextual_suggestion.get_contextual_suggestions(),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  useUnitTypesQuery: ({ config = {}, enabled } = {}) => {
    return useQuery(
      ['unit-types'],
      () => API_ENDPOINTS.budgets.get_detail_sheet_unit_type(),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  usePermissionsQuery: ({ config = {}, enabled } = {}) => {
    return useQuery(
      ['get-permissions'],
      () => API_ENDPOINTS.budgets.get_permissions(),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },

  useCollaboratorsQuery: ({ config = {}, enabled, budgetId }) => {
    return useQuery(
      ['get-collaborators', budgetId],
      () => API_ENDPOINTS.budgets.get_collaborators(budgetId),
      {
        retry: false,
        ...config,
        enabled,
      }
    );
  },

  useBudgetPermissionsQuery: ({ config = {}, enabled, budgetId }) => {
    return useQuery(
      ['get-budget-permissions', budgetId],
      () => API_ENDPOINTS.budgets.get_budget_permissions(budgetId),
      {
        retry: false,
        ...config,
        enabled,
      }
    );
  },

  useBudgetDataQuery: ({ config = {}, enabled, budgetId }) => {
    return useQuery(
      ['get-budget-data', budgetId],
      () => API_ENDPOINTS.budgets.get_budget_data(budgetId),
      {
        retry: false,
        ...config,
        enabled,
      }
    );
  },

  useBudgetDetailsDataQuery: ({ config = {}, enabled, payload }) => {
    return useQuery(
      ['get-budget-details-data', payload],
      () => API_ENDPOINTS.budgets.get_budget_details_data(payload),
      {
        retry: false,
        ...config,
        enabled,
      }
    );
  },
};

export const useFringeQuery = {
  useFringeDetailsQuery: ({ config = {}, enabled }) => {
    return useQuery(
      ['get-fringe-details'], // Query key for caching and refetching
      () => API_ENDPOINTS.fringes.list_fringe(), // API call
      {
        retry: false, // No retry on failure
        enabled, // Conditionally enable the query
        ...config, // Allow passing additional config options
      }
    );
  },
};

export const useGroupFringeQuery = {
  useGroupFringeDetailsQuery: ({ config = {}, enabled }) => {
    return useQuery(
      ['get-group-fringe-details'], // Query key for caching and refetching
      () => API_ENDPOINTS.groups.list_group(), // API call
      {
        retry: false, // No retry on failure
        enabled, // Conditionally enable the query
        ...config, // Allow passing additional config options
      }
    );
  },
};

export const useAccountSettingQueries = {
  useProfileQuery: ({ config = {}, enabled }) => {
    return useQuery(
      ['user-profile'],
      async () => await API_ENDPOINTS.account_settings.get_user_profile(),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },
  useSearchUserQuery: ({ config = {}, enabled, searchParam } = {}) => {
    return useQuery(
      ['user-search', searchParam],
      () => API_ENDPOINTS.account_settings.search_user(searchParam),
      {
        retry: false,
        enabled,
        refetchOnMount: false,
        ...config,
      }
    );
  },
};
export const useTabsQueries = {
  useOpenTabsQuery: ({ config = {}, enabled }) => {
    return useQuery(
      ['get-open-tabs'],
      () => API_ENDPOINTS.tabs.get_open_tabs(),
      {
        retry: false,
        enabled,
        ...config,
      }
    );
  },
};
