import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';

// import './styles.scss';
import './styles.scss';
import PropTypes from 'prop-types';
import { toastStyle } from 'utils/helper';
import axios from 'axios';

const fetchGroups = async () => {
  try {
    const storedToken = JSON.parse(localStorage.getItem('authToken')); // Parse the stored token object
    const currentTime = new Date().toISOString();

    // Check if token is expired
    if (storedToken.expiryTime <= currentTime) {
      console.error('Token has expired. Please log in again.');
      return [];
    }

    console.log('Token is valid:', storedToken.token);

    const response = await axios.get('/fringe/groups-fringe/', {
      headers: {
        Authorization: `Token ${storedToken.token}`, // Adjust format if necessary
      },
    });

    console.log('Groups:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      'Error fetching groups:',
      error.response?.status,
      error.response?.data || error.message
    );
    return [];
  }
};

const EditFringe = props => {
  const [fringeTypeOptions, setFringeTypeOptions] = useState([
    { label: 'Percentage', value: 'percentage' },
    { label: 'Flat Fee', value: 'flat_fee' },
  ]);
  const [showValueInput, setShowValueInput] = useState(false); // State to toggle value input
  const [groupOptions, setGroupOptions] = useState([]); // State for groups
  const [initialValues, setInitialValues] = useState({});
  const {
    updateFrindeMutation,
    refetchFringe,
    hideUpdateFringeModal,
    selectTab,
    clearBudgetRename,
    refetchFringeId,
  } = useHomeScreenContext();
  console.log('props', props);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: props?.frineName || '',
      display_name: props?.fringeDisplay || '',
      id: props?.fringeId,
      description: props?.fringeDescription || '',
      abbreviation: props?.fringeAbbreviation || '',
      fringe_type: props?.fringeType || '',
      value: props?.fringeValue || '',
      base_cap: props?.fringeBaseCap || '',
      cap_on_total: props?.fringeCapOnTotal || '',
      group: props?.fringeGroup || '',
    },
  });

  const handleCreateBudget = () =>
    handleSubmit(async data => {
      console.log('data', data);
      try {
        const response = await updateFrindeMutation.mutateAsync({
          name: data.name,
          description: data.description,
          abbreviation: data.abbreviation,
          display_name: data.display_name,
          type: data.fringe_type,
          value: data.value,
          base_cap: data.base_cap,
          cap_on_total: data.cap_on_total,
          id: props?.fringeId,
          group: Array.isArray(data.group)
            ? data.group.map(groupItem => groupItem.value)
            : [],
        });
        const newBudget = response?.data;
        newBudget?.id && selectTab(newBudget.id, newBudget);
        refetchFringe?.();
        refetchFringeId?.();
        toast.success(`Fringe updated successfully`, toastStyle);
        reset();
        handleCancel();
        props?.refetch();
        // window?.location?.reload();
      } catch (error) {
        console.log(error);
        const message =
          error?.response?.data?.template_type?.[0] ||
          error?.response?.data?.name?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.error(
          message ||
            `An error occurred while updating budget. Please try again later`,
          toastStyle
        );
      }
    });

  const handleCancel = () => {
    hideUpdateFringeModal();
    clearBudgetRename();
    reset();
    props?.closeFolder();
  };

  // when i try to update the fringe without changing the group it sends back group name instead of group id

  useEffect(() => {
    const loadGroups = async () => {
      const groupsResponse = await fetchGroups();
      // groupsResponse.data might look like: [{ id: 1, name: 'Group A' }, { id: 2, name: 'Group B' }, ...]

      // Convert them to your FormSelect-compatible shape
      const formattedGroups =
        groupsResponse.data?.map(group => ({
          label: group.name,
          value: group.id,
        })) || [];

      setGroupOptions(formattedGroups);

      // If props.fringeGroup is a string or array of group NAMEs,
      // convert them to the matching { label, value } objects from formattedGroups
      if (props.fringeGroup) {
        // 1) ensure it's an array
        const fringeGroupArray = Array.isArray(props.fringeGroup)
          ? props.fringeGroup
          : [props.fringeGroup];

        // 2) find the matching group objects by comparing labels
        const matchedGroups = formattedGroups.filter(option =>
          fringeGroupArray.includes(option.label)
        );

        // 3) set the default value in React Hook Form so the user doesn't have to reselect
        setValue('group', matchedGroups);
      }

      // If you want the default fringe type to be 'percentage' if not provided
      setValue('fringe_type', props?.fringeType || 'percentage');
    };

    loadGroups();
  }, [props.fringeGroup, props.fringeType, setValue]);

  return (
    <Modal
      onHide={handleCancel}
      show={props?.showCreateFringeModal}
      size="sm"
      className="create-budget"
      bodyClass="create-budget__body"
      centered={true}
    >
      <h1 className="create-budget__body__header">
        Edit Fringe{' '}
        {watch('name') && (
          <span className="budget-name">&ldquo;{watch('name')}&rdquo;</span>
        )}
      </h1>
      <Form className="overflow-hidden" onSubmit={handleCreateBudget()}>
        <FormInput
          label="Fringe Name"
          type="text"
          error={errors?.name?.message}
          placeholder="Please enter a budget title"
          {...register('name', {
            required: 'Fringe name is required',
            validate: value =>
              value.trim() !== '' || 'Fringe name cannot be just spaces',
          })}
        />
        <FormInput
          label="Fringe Display Name"
          type="text"
          error={errors?.display_name?.message}
          placeholder="Please enter a short name"
          {...register('display_name', {
            required: 'Fringe display name is required',
            validate: value =>
              value.trim() !== '' ||
              'Fringe display name cannot be just spaces',
          })}
        />
        <FormInput
          label="Fringe Description"
          type="text"
          error={errors?.description?.message}
          placeholder="Please provide the description"
          {...register('description')}
        />
        <FormInput
          label="Fringe Letter Abbreviation"
          type="text"
          error={errors?.abbreviation?.message}
          placeholder="Please enter fringe abbreviation"
          {...register('abbreviation', {
            required: 'Fringe abbreviation is required',
            validate: value =>
              value.trim() !== '' ||
              'Fringe abbriviation cannot be just spaces',
            maxLength: {
              value: 5,
              message: 'Abbreviation cannot exceed 5 letters',
            },
            pattern: {
              value: /^[A-Z]{1,5}$/, // 1 to 5 uppercase letters (A-Z)
              message: 'Must be 1 to 5 uppercase letters (A-Z)',
            },
          })}
        />
        <div className="toggle-button-fringes">
          <label className="Type-button">Type</label>
          <div className="toggle-button-fringe">
            <Button
              label="Percentage"
              className={`toggle-button-one ${
                watch('fringe_type') === 'percentage' ? 'active' : ''
              }`}
              onClick={() => setValue('fringe_type', 'percentage')}
              variant={
                watch('fringe_type') === 'percentage' ? 'primary' : 'secondary'
              }
            />
            <Button
              label="Flat Fee"
              className={`toggle-button-one ${
                watch('fringe_type') === 'flat fee' ? 'active' : ''
              }`}
              onClick={() => setValue('fringe_type', 'flat fee')}
              variant={
                watch('fringe_type') === 'flat fee' ? 'primary' : 'secondary'
              }
            />
          </div>
          {errors?.fringe_type && (
            <p className="error-message">{errors.fringe_type.message}</p>
          )}
        </div>
        <FormInput
          label="Value"
          type="text"
          error={errors?.value?.message}
          placeholder="Please enter a value"
          {...register('value', { required: 'Value is required' })}
        />
        <FormInput
          label="Base Cap"
          type="text"
          error={errors?.base_cap?.message}
          placeholder="Please enter a value"
          {...register('base_cap')}
        />
        <FormInput
          label="Cap on Total"
          type="text"
          error={errors?.cap_on_total?.message}
          placeholder="Please enter a value"
          {...register('cap_on_total')}
        />
        <FormSelect
          label="Group"
          placeholder={props?.fringeGroup || 'Please select a Group'}
          options={groupOptions}
          error={errors?.group?.message}
          name="group"
          control={control}
          className="mb-5"
          isMulti={true}
        />
        <div className="create-budget__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="create-budget__action__cancel"
            variant="red-outlined"
            onClick={handleCancel}
          />
          <Button
            label="Confirm"
            className="create-budget__action__confirm"
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

EditFringe.propTypes = {
  parentid: PropTypes.string, // Assuming parentid is a string, adjust the type accordingly
  fringeId: PropTypes.string,
  showCreateFringeModal: PropTypes.bool,
  frineName: PropTypes.string,
  BudgetTypeData: PropTypes.string,
  closeFolder: PropTypes.func,
  refetch: PropTypes.func.isRequired, // Adjust the type based on the actual type of refetch
  fringeDescription: PropTypes.string,
  fringeAbbreviation: PropTypes.string,
  fringeDisplay: PropTypes.string,
  fringeType: PropTypes.string,
  fringeValue: PropTypes.string,
  fringeCapOnTotal: PropTypes.string,
  fringeBaseCap: PropTypes.string,
  fringeGroup: PropTypes.string,
};

EditFringe.defaultProps = {
  fringeId: '',
  parentid: '', // Default value for parentid (adjust based on actual default)
  showCreateFringeModal: false, // Default value for showAddFolder (adjust based on actual default)
  closeFolder: undefined,
  frineName: '',
  BudgetTypeData: '',
  fringeDescription: '',
  fringeAbbreviation: '',
  fringeDisplay: '',
  fringeType: '',
  fringeValue: '',
  fringeBaseCap: '',
  fringeCapOnTotal: '',
  fringeGroup: '',
};

export default EditFringe;
