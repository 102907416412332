import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Maximum desired table height before starting a new "page"
const MAX_TABLE_HEIGHT_IN_PIXELS = 1040;

/**
 * Helper function to chunk (paginate) your table rows into separate
 * "pages" based on the height constraints.
 */
const paginateData = (tableData = [], measureRowHeight) => {
  const pages = [];
  let currentRows = [];

  for (let i = 0; i < tableData.length; i++) {
    currentRows.push(tableData[i]);

    // If adding this new row makes the table exceed the max height:
    if (measureRowHeight(currentRows) > MAX_TABLE_HEIGHT_IN_PIXELS) {
      // Remove the overflowing row
      const lastRow = currentRows.pop();

      // Push the current page (minus the last row)
      pages.push([...currentRows]);

      // Start a new page with the one that overflowed
      currentRows = [lastRow];
    }
  }

  // If any leftover rows remain, push them as the final page
  if (currentRows.length) {
    pages.push(currentRows);
  }

  return pages;
};

const PreviewPrintLayout = ({
  tableData,
  printLayoutHeader,
  printLayoutFooter,
  productionDetails,
  tableHeader,
  tableClassName,
  showTitle,
  classNamePrefix = '',
  tableProps,
}) => {
  const [pages, setPages] = useState([]);
  const containerRefs = useRef([]);

  // Create a combined class name for the page container
  const className = (classNamePrefix ? classNamePrefix : '') + 'print-layout';

  /**
   * This function estimates height based on the number of rows.
   * In a real-world scenario, you could measure an offscreen / hidden
   * table to get the true height instead of using a fixed multiplier.
   */
  const measureRowHeight = rows => {
    const ROW_HEIGHT_APPROX = 100; // Approx. each row is 40px tall
    return rows.length * ROW_HEIGHT_APPROX;
  };

  /**
   * Re-paginate whenever `tableData` changes
   */
  useEffect(() => {
    if (!tableData || tableData.length === 0) {
      setPages([]);
      return;
    }

    const newPages = paginateData(tableData, measureRowHeight);
    setPages(newPages);
  }, [tableData]);

  return (
    <>
      {pages.map((pageRows, pageIndex) => {
        // If you have "footer" rows, you can separate them out here
        const tableFooter = [];
        const normalRows = [];

        pageRows.forEach(row => {
          if (row.type === 'swimlaneFooter') {
            tableFooter.push(row);
          } else {
            normalRows.push(row);
          }
        });

        return (
          <React.Fragment key={`print-page-${pageIndex}`}>
            <div
              className={className}
              ref={el => (containerRefs.current[pageIndex] = el)}
              // This style ensures each "page" container is at least tall enough,
              // but won't exceed 1040 thanks to pagination.
              style={{ minHeight: MAX_TABLE_HEIGHT_IN_PIXELS }}
            >
              {printLayoutHeader}

              {/*
                Only render the title + productionDetails on the first page.
                If you want them on every page, remove the check (pageIndex === 0).
              */}
              {pageIndex === 0 && (
                <>
                  {showTitle && (
                    <div className={`${className}__title`}>
                      {/* e.g. Your Budget Name & Type here */}
                      My Budget Name (Type)
                    </div>
                  )}
                  {/* Render production details on first page */}
                  {productionDetails}
                </>
              )}

              <div className={`${className}__details`}>
                <Table className={tableClassName} {...tableProps}>
                  {tableHeader}
                  <tbody>
                    {normalRows.map((row, index) =>
                      row.renderMethod(row.rowData, `row-${pageIndex}-${index}`)
                    )}
                  </tbody>
                  {tableFooter.length > 0 && (
                    <tfoot>
                      {tableFooter.map((row, index) =>
                        row.renderMethod(
                          row.rowData,
                          `footer-${pageIndex}-${index}`
                        )
                      )}
                    </tfoot>
                  )}
                </Table>
              </div>

              {printLayoutFooter}
            </div>

            {/*
              Page break between pages. You can also style it to enforce
              a print break in CSS with `@media print`.
            */}
            {pageIndex + 1 < pages.length && <div className="page-break" />}
          </React.Fragment>
        );
      })}
    </>
  );
};

PreviewPrintLayout.propTypes = {
  tableData: PropTypes.array.isRequired,
  printLayoutHeader: PropTypes.node.isRequired,
  printLayoutFooter: PropTypes.node.isRequired,
  productionDetails: PropTypes.node.isRequired,
  tableHeader: PropTypes.node.isRequired,
  tableClassName: PropTypes.string.isRequired,
  showTitle: PropTypes.bool.isRequired,
  classNamePrefix: PropTypes.string.isRequired,
  tableProps: PropTypes.object.isRequired,
};

export default PreviewPrintLayout;
