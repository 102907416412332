import React from 'react';

import ALL_ROUTES from 'config/routes';
import { ReactComponent as ProfileIcon } from 'assets/images/profile.svg';
import { ReactComponent as SubscriptionIcon } from 'assets/images/attach-money.svg';
import { ReactComponent as NotificationIcon } from 'assets/images/notifications.svg';
import { ReactComponent as PaymentIcon } from 'assets/images/credit-card.svg';
import { ReactComponent as PasswordIcon } from 'assets/images/lock.svg';
import { ReactComponent as ContactIcon } from 'assets/images/phone.svg';
import { ReactComponent as InviteIcon } from 'assets/images/person-add.svg';
import { ReactComponent as SupportIcon } from 'assets/images/feedback.svg';
import { ReactComponent as PrivacyIcon } from 'assets/images/policy.svg';
import { ReactComponent as TosIcon } from 'assets/images/gavel.svg';
import { ReactComponent as FaqIcon } from 'assets/images/help.svg';
export const menuItems = [
  {
    label: 'My Budgets',
    key: 'myBudgets',
    show: true,
    path: 'budget-listing',
  },
  {
    label: 'File',
    key: 'file',
    show: true,
    sub_menu_items: [
      {
        label: 'New Budget',
        key: 'new-budget',
        show: true,
      },
      {
        label: 'Open Budget',
        key: 'open-budget',
        show: false,
      },
      {
        label: 'Open Recent',
        key: 'open-recent',
        show: false,
      },
      {
        label: 'Import Budget',
        key: 'import-budget',
        show: false,
      },
      {
        label: 'Duplicate Budget',
        key: 'duplicate-budget-with-values',
        show: true,
      },
      {
        label: 'Duplicate Budget Structure',
        key: 'duplicate-budget',
        show: true,
      },
      {
        label: 'Rename Budget',
        key: 'rename-budget',
        show: true,
      },
      {
        label: 'Move to Folder',
        key: 'move-to-folder',
        show: false,
      },
      {
        type: 'divider',
        show: true,
      },
      {
        label: 'Export PDF',
        key: 'export-to-pdf',
        show: true,
      },
      {
        label: 'Print Budget',
        key: 'print-budget',
        show: true,
      },
      {
        label: 'Print Setup',
        key: 'print-setup',
        show: false,
      },
      {
        type: 'divider',
      },
      {
        label: 'Budget Settings',
        key: 'budget-settings',
        show: true,
      },
      {
        label: 'Budget Information',
        key: 'budget-information',
        show: false,
      },
      {
        type: 'divider',
        show: true,
      },
      {
        label: 'Archive Budget',
        key: 'archive-budget',
        show: false,
      },
      {
        label: 'Delete Budget',
        key: 'delete-budget',
        show: true,
      },
    ],
  },
  {
    label: 'Edit',
    key: 'edit',
    show: false,
    //show_for: [ALL_ROUTES.HOME_SCREEN],
    sub_menu_items: [
      {
        label: 'Undo',
        key: 'undo',
      },
      {
        label: 'Redo',
        key: 'redo',
      },
      {
        type: 'divider',
      },
      {
        label: 'Cut',
        key: 'cut',
      },
      {
        label: 'Copy',
        key: 'copy',
      },
      {
        label: 'Paste',
        key: 'paste',
      },
      {
        label: 'Select all',
        key: 'select-all',
      },
      {
        label: 'Delete',
        key: 'delete',
      },
      {
        type: 'divider',
      },
      {
        label: 'Find',
        key: 'find',
      },
      {
        label: 'Find & Replace',
        key: 'find-replace',
      },
    ],
  },
  {
    label: 'Share',
    key: 'share',
    show_for: [ALL_ROUTES.HOME_SCREEN],
    sub_menu_items: [
      {
        label: 'Share Budget',
        key: 'share-budget',
        show: true,
      },
      {
        label: 'Export to PDF',
        key: 'export-to-pdf',
        type: 'link',
        show: true,
        path: ALL_ROUTES.HOME_SCREEN_CHILDREN.PRINT_BUDGET,
      },
      {
        label: 'Email Budget as PDF',
        key: 'email-as-pdf',
        show: false,
      },
      {
        label: 'Email Share Link',
        key: 'email-share-link',
        show: false,
      },
      {
        label: 'Manage Collaborators',
        key: 'manage-collaborators',
        show: true,
      },
      {
        type: 'divider',
        show: true,
      },
      {
        label: 'Print Budget',
        key: 'print-budget',
        show: true,
      },
    ],
  },
  {
    label: 'View',
    key: 'view',
    show: false,
    //show_for: [ALL_ROUTES.HOME_SCREEN],
    sub_menu_items: [
      {
        label: 'Hide/Show Fringes',
        key: 'hide-show-fringes',
      },
      {
        label: 'Hide/Show Suggestions',
        key: 'hide-show-suggestions',
      },
      {
        label: 'Hide/Show Insights Pane',
        key: 'hide-show-insights',
      },
      {
        label: 'Hide/Show tabs',
        key: 'hide-show-tabs',
      },
      {
        type: 'divider',
      },
      {
        label: 'Zoom In',
        key: 'zoom-in',
      },
      {
        label: 'Zoom Out',
        key: 'zoom-out',
      },
    ],
  },
  {
    label: 'Setup',
    key: 'setup',
    show: false,
    // show_for: [ALL_ROUTES.HOME_SCREEN],
    sub_menu_items: [
      {
        label: 'Rates',
        key: 'rates',
      },
      {
        label: 'Cast',
        key: 'cast',
      },
      {
        label: 'Crew',
        key: 'crew',
      },
      {
        label: 'Locations',
        key: 'locations',
      },
      {
        label: 'Shooting Days',
        key: 'shooting-days',
      },
    ],
  },
  {
    label: 'Add',
    key: 'add',
    show: false,
    //show_for: [ALL_ROUTES.HOME_SCREEN],
    sub_menu_items: [
      {
        label: 'Cast',
        key: 'cast',
      },
      {
        label: 'Crew',
        key: 'crew',
      },
      {
        label: 'Location',
        key: 'location',
      },
    ],
  },
  {
    label: 'Budget Settings',
    key: 'budget_settings',
    show_for: [ALL_ROUTES.HOME_SCREEN],
    sub_menu_items: [
      { label: 'Budget Settings', key: 'budget-settings', show: true },
      {
        label: 'Fringe Settings',
        key: 'fringes',
        show: true,
        path: ALL_ROUTES.HOME_SCREEN_CHILDREN.FRINGES_DETAILS,
        type: 'link',
      },
    ],
  },
  {
    label: 'Settings',
    key: 'settings',
    show_for: [ALL_ROUTES.HOME_SCREEN],
    sub_menu_items: [
      {
        label: 'User Settings',
        key: 'account-settings',
        type: 'link',
        show: true,
        path: ALL_ROUTES.HOME_SCREEN_CHILDREN.ACCOUNT_SETTINGS,
      },
    ],
  },
  {
    label: 'Help',
    key: 'help',
    show: true,
    sub_menu_items: [
      {
        label: 'Keyboard Shortcuts',
        key: 'keyboard-shortcuts',
        show: true,
        type: 'link',
        path: 'https://cuboid-hexaflexagon-kzxk.squarespace.com/tips-tricks',
        target: '_blank',
      },
      {
        label: 'Website',
        key: 'website',
        show: true,
        type: 'link',
        path: 'https://cuboid-hexaflexagon-kzxk.squarespace.com/',
        target: '_blank',
      },
      {
        label: 'User Guide',
        key: 'user-guide',
        show: true,
        type: 'link',
        path: 'https://cuboid-hexaflexagon-kzxk.squarespace.com/learn',
        target: '_blank',
      },
      {
        label: 'User Videos',
        key: 'user-videos',
        show: true,
        type: 'link',
        path: 'https://www.youtube.com/embed/0hw3CzfS0YM?si=gXjGiB70eY_0ml-w',
        target: '_blank',
      },
      {
        type: 'divider',
        show: false,
      },
      {
        label: 'Privacy Policy',
        key: 'privacy-policy',
        show: true,
        type: 'link',
        path: 'https://cuboid-hexaflexagon-kzxk.squarespace.com/privacy-policy',
        target: '_blank',
      },
      {
        label: 'Terms of Use',
        key: 'terms-of-use',
        show: true,
        type: 'link',
        path: 'https://cuboid-hexaflexagon-kzxk.squarespace.com/terms-of-service',
        target: '_blank',
      },
      {
        type: 'divider',
        show: false,
      },
      {
        label: 'Report an Issue',
        key: 'report-issue',
        show: true,
        type: 'link',
        path: 'mailto: jon@cinebudgetpro.com',
        target: '_blank',
      },
    ],
  },
];

export const accountSettingsMenu = [
  {
    label: 'Profile',
    key: 'profile',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PROFILE,
    icon: <ProfileIcon />,
  },
  {
    label: 'Payments',
    key: 'payments',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PAYMENTS,
    icon: <PaymentIcon />,
    hidden: true,
  },
  {
    label: 'Subscription',
    key: 'subscription',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.SUBSCRIPTION,
    icon: <SubscriptionIcon />,
    hidden: true,
  },
  {
    label: 'Notifications',
    key: 'notifications',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.NOTIFICATIONS,
    icon: <NotificationIcon />,
    hidden: true,
  },
  {
    label: 'Change Password',
    key: 'change-password',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.CHANGE_PASSWORD,
    icon: <PasswordIcon />,
    hide_for: ['social_signup'],
  },
  {
    label: 'Contact Us',
    key: 'contact-us',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.CONTACT_US,
    icon: <ContactIcon />,
    hidden: true,
  },
  {
    label: 'Invite Friends',
    key: 'invite-friends',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.INVITE_FRIENDS,
    icon: <InviteIcon />,
    hidden: true,
  },
  {
    label: 'Support & Feedback',
    key: 'support-feedback',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.SUPPORT_FEEDBACK,
    icon: <SupportIcon />,
    hidden: true,
  },
  {
    label: 'Privacy Policy',
    key: 'privacy-policy',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PRIVACY_POLICY,
    icon: <PrivacyIcon />,
    hidden: true,
  },
  {
    label: 'Terms & Conditions',
    key: 'terms-conditions',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.TERMS_CONDITIONS,
    icon: <TosIcon />,
    hidden: true,
  },
  {
    label: 'FAQs',
    key: 'faqs',
    path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.FAQS,
    icon: <FaqIcon />,
    hidden: true,
  },
];

export const profileOptions = [
  { label: 'Student', value: '1' },
  { label: 'Content Creator', value: '2' },
  { label: 'Commercial Producer', value: '3' },
  { label: 'Guest', value: '4' },
  // { label: 'Admin', value: '5' },
];

export const budgetMenu = [
  { label: 'Rename Budget', value: 'rename-budget' },
  {
    label: 'Duplicate Budget',
    value: 'duplicate-budget-with-values',
  },
  { label: 'Duplicate Budget Structure', value: 'duplicate-budget' },
  { label: 'Export PDF', value: 'export-to-pdf' },
  { label: 'Print Budget', value: 'print-budget' },
  { label: 'Budget Settings', value: 'budget-settings' },
  //{ label: 'Budget Information', value: 'budget-information' },
  { label: 'Delete Budget', value: 'delete-budget' },
  { label: 'Close Budget Tab', value: 'close-budget-tab' },
];

export const profileMenu = [
  { label: 'Profile', value: 'profile' },
  { label: 'Log Out', value: 'logout' },
];

export const shootingLocations = [
  { label: 'Los Angeles, CA', value: 'Los Angeles, CA' },
  { label: 'Atlanta, GA', value: 'Atlanta, GA' },
  { label: 'New York, CA', value: 'New York, CA' },
  { label: 'San Francisco, CA', value: 'San Francisco, CA' },
  { label: 'London, UK', value: 'London, UK' },
  { label: 'Rome, Italy', value: 'Rome, Italy' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Paris, France', value: 'Paris, France' },
  { label: 'Tokyo, Japan', value: 'Tokyo, Japan' },
  { label: 'Sydney, Australia', value: 'Sydney, Australia' },
  { label: 'Berlin, Germany', value: 'Berlin, Germany' },
  { label: 'New Orleans, LA', value: 'New Orleans, LA' },
  { label: 'Miami, FL', value: 'Miami, FL' },
  { label: 'Chicago, IL', value: 'Chicago, IL' },
  { label: 'Toronto, Canada', value: 'Toronto, Canada' },
  { label: 'Vancouver, Canada', value: 'Vancouver, Canada' },
  {
    label: 'Prague, Czechia (Czech Republic)',
    value: 'Prague, Czechia (Czech Republic)',
  },
  { label: 'Albuquerque, NM', value: 'Albuquerque, NM' },
  { label: 'Montreal, Canada', value: 'Montreal, Canada' },
  { label: 'Mumbai, India', value: 'Mumbai, India' },
  { label: 'Cape Town, South Africa', value: 'Cape Town, South Africa' },
  { label: 'Budapest, Hungary', value: 'Budapest, Hungary' },
  { label: 'Istanbul, Turkey', value: 'Istanbul, Turkey' },
  { label: 'Madrid, Spain', value: 'Madrid, Spain' },
  { label: 'Barcelona, Spain', value: 'Barcelona, Spain' },
  { label: 'Vienna, Austria', value: 'Vienna, Austria' },
  { label: 'Amsterdam, Netherlands', value: 'Amsterdam, Netherlands' },
  { label: 'Lisbon, Portugal', value: 'lisbon, Portugal' },
  { label: 'Hong Kong', value: 'Hong Kong' },
  { label: 'Seoul, South Korea', value: 'Seoul, South Korea' },
  { label: 'Beijing, China', value: 'Beijing, China' },
  { label: 'Buenos Aires, Argentina', value: 'Buenos Aires, Argentina' },
  { label: 'Sao Paulo, Brazil', value: 'Sao Paulo, Brazil' },
  { label: 'Rio de Janeiro, Brazil', value: 'Rio de Janeiro, Brazil' },
  { label: 'Bogota, Colombia', value: 'Bogota, Colombia' },
  { label: 'Lima, Peru', value: 'Lima, Peru' },
  { label: 'Mexico City, Mexico', value: 'Mexico City, Mexico' },
  { label: 'Santiago, Chile', value: 'Santiago, Chile' },
  { label: 'Guardalajara, Mexico', value: 'Guardalajara, Mexico' },
  { label: 'San Diego, CA', value: 'San Diego, CA' },
  { label: 'Nashville, TN', value: 'Nashville, TN' },
  { label: 'Austin, TX', value: 'Austin, TX' },
  { label: 'Denver, CO', value: 'Denver, CO' },
  { label: 'Phoenix, AZ', value: 'Phoenix, AZ' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Melbourne, Australia', value: 'Melbourne, Australia' },
  { label: 'Boston, MA', value: 'Boston, MA' },
];

export const userActivity = {
  CONTEXTUAL_SUGGESTIONS: 1,
  BUDGET_EXPORTED: 2,
  BUDGET_PRINTED: 3,
};
