import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for props validation
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import './styles.scss';
import { useFringeMutation } from 'utils/api/mutations'; // Add the fetchGroups function
import axios from 'axios';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import CreateGroups from 'components/CreateGroups';
import Select, { components } from 'react-select'; // Import components
import { useGroupFringeQuery } from 'utils/api/queries';

const CreateFringe = props => {
  const [groupOptions, setGroupOptions] = useState([]); // State for groups
  const createFringeMutation = useFringeMutation.useCreateFringeMutation();
  const {
    createTabsMutation,
    showCreateGroupsModal,
    setshowCreateGroupsModal,
  } = useHomeScreenContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
    setValue,
  } = useForm();

  const {
    data: budget_details,
    isLoading,
    refetch,
  } = useGroupFringeQuery.useGroupFringeDetailsQuery({});

  useEffect(() => {
    if (budget_details) {
      const formattedGroups =
        budget_details?.data?.data?.map(group => ({
          label: group.name,
          value: group.id,
        })) || [];
      setGroupOptions(formattedGroups);
    }
    setValue('fringe_type', 'percentage');
  }, [budget_details, setValue]);

  const onSubmit = data => {
    // Build the payload JSON step by step
    const payload = {
      name: data.name,
      description: data.description,
      abbreviation: data.abbreviation,
      display_name: data.display_name,
      type: data.fringe_type,
      value: data.value,
      base_cap: data.base_cap, // Use dash if no value
      cap_on_total: data.cap_on_total, // Use dash if no value
      // group is optional, so check if it exists before mapping
      group: data.group?.map(group => group.value) || [],
    };

    console.log('Final Payload:', payload);

    createFringeMutation.mutate(payload, {
      onSuccess: () => {
        props?.closeFolder();
        props?.onFringeCreated();
        reset();
      },

      onError: error => {
        console.error('Mutation error:', error);
      },
    });
  };

  const CustomMenuList = ({ children, ...rest }) => {
    return (
      <components.MenuList {...rest}>
        {children}
        <div
          style={{
            padding: '8px',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: '#f8f9fa',
            borderTop: '1px solid #e9ecef',
          }}
          onClick={() => setshowCreateGroupsModal(true)} // Open modal on click
        >
          + Add Group
        </div>
      </components.MenuList>
    );
  };

  CustomMenuList.propTypes = {
    children: PropTypes.node.isRequired, // Validate children
  };

  return (
    <>
      <Modal
        onHide={props?.closeFolder}
        show={props?.showCreateFringeModal}
        size="sm"
        className="create-budget"
        bodyClass="create-budget__body"
        centered
      >
        <h1 className="create-budget__body__header">Add New Fringe</h1>
        <Form className="overflow-hidden" onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label="Full Fringe Name"
            type="text"
            error={errors?.name?.message}
            placeholder="Please enter a name"
            {...register('name', {
              required: 'Fringe name is required',
              validate: value =>
                value.trim() !== '' || 'Fringe name cannot be just spaces',
            })}
          />
          <FormInput
            label="Fringe Display Name"
            type="text"
            error={errors?.display_name?.message}
            placeholder="Please enter a short name"
            {...register('display_name', {
              required: 'Fringe display name is required',
              validate: value =>
                value.trim() !== '' ||
                'Fringe display name cannot be just spaces',
            })}
          />
          <FormInput
            label="Fringe Description"
            type="text"
            error={errors?.description?.message}
            placeholder="Please provide the description"
            {...register('description')}
          />
          <FormInput
            label="Fringe Letter Abbreviation"
            type="text"
            error={errors?.abbreviation?.message}
            placeholder="Please enter fringe abbreviation"
            {...register('abbreviation', {
              required: 'Fringe abbreviation is required',
              validate: value =>
                value.trim() !== '' ||
                'Fringe abbriviation cannot be just spaces',
              maxLength: {
                value: 5,
                message: 'Abbreviation cannot exceed 5 letters',
              },
              pattern: {
                value: /^[A-Z]{1,5}$/, // 1 to 5 uppercase letters (A-Z)
                message: 'Must be 1 to 5 uppercase letters (A-Z)',
              },
            })}
          />
          <div className="toggle-button-fringes">
            <label className="Type-button">Type</label>
            <div className="toggle-button-fringe">
              <Button
                label="Percentage"
                className={`toggle-button-one ${
                  watch('fringe_type') === 'percentage' ? 'active' : ''
                }`}
                onClick={() => setValue('fringe_type', 'percentage')}
                variant={
                  watch('fringe_type') === 'percentage'
                    ? 'primary'
                    : 'secondary'
                }
              />
              <Button
                label="Flat Fee"
                className={`toggle-button-one ${
                  watch('fringe_type') === 'flat fee' ? 'active' : ''
                }`}
                onClick={() => setValue('fringe_type', 'flat fee')}
                variant={
                  watch('fringe_type') === 'flat fee' ? 'primary' : 'secondary'
                }
              />
            </div>
            {errors?.fringe_type && (
              <p className="error-message">{errors.fringe_type.message}</p>
            )}
          </div>
          <FormInput
            label="Value"
            type="text"
            error={errors?.value?.message}
            placeholder="Please enter a value"
            {...register('value', {
              required: 'Value is required',
              validate: value =>
                value.trim() !== '' ||
                'Fringe value name cannot be just spaces',
            })}
          />
          <FormInput
            label="Base Cap"
            type="text"
            error={errors?.base_cap?.message}
            placeholder="Enter Base Cap"
            {...register('base_cap', {
              // It's optional, but if you also want to disallow commas here:
            })}
          />
          <FormInput
            label="Cap on Total"
            type="text"
            error={errors?.cap_on_total?.message}
            placeholder="Enter Cap on Total"
            {...register('cap_on_total', {
              // It's optional, but if you also want to disallow commas here:
            })}
          />
          <div className="group-select-container">
            <FormSelect
              label="Group"
              placeholder="Please select a Group"
              options={groupOptions} // Only real groups
              error={errors?.group?.message}
              name="group"
              control={control}
              className="mb-5"
              isMulti={true}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              components={{ MenuList: CustomMenuList }} // Ensures proper rendering in modal
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }), // Ensure dropdown is not hidden
                menu: base => ({
                  ...base,
                  Height: '200px', // Limit height
                  overflowY: 'auto', // Enable scrolling
                }),
                menuList: base => ({
                  ...base,
                  maxHeight: '200px', // Ensure scrolling works
                  overflowY: 'auto',
                }),
                control: base => ({
                  ...base,
                  minHeight: '40px', // Adjust as needed
                }),
              }}
            />
          </div>

          <div className="create-budget__body__action">
            <Button
              label="Cancel"
              type="reset"
              className="create-budget__action__cancel"
              variant="red-outlined"
              onClick={props?.closeFolder}
            />
            <Button
              label="Create"
              className="create-budget__action__confirm"
              type="submit"
            />
          </div>
        </Form>
      </Modal>
      {showCreateGroupsModal && (
        <CreateGroups
          closeFolder={() => {
            setshowCreateGroupsModal(false); // Close modal
            refetch(); // Refetch groups after creating a new one
          }}
          showCreateGroupsModal={showCreateGroupsModal}
          refetch={refetch}
        />
      )}
    </>
  );
};

// Define propTypes for props validation
CreateFringe.propTypes = {
  closeFolder: PropTypes.func.isRequired,
  showCreateFringeModal: PropTypes.bool.isRequired,
  onFringeCreated: PropTypes.func.isRequired,
};

export default CreateFringe;
