import React, { useEffect, useRef, useMemo, useState } from 'react';
import randomColor from 'randomcolor';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import 'react-resizable/css/styles.css';

import { ReactComponent as ExpandIcon } from 'assets/images/expand-more.svg';
import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg';
import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow-back.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/images/arrow-forward.svg';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import DropDownMenu from 'components/DropDownMenu';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import GridLoader from 'components/GridLoader';
import Chip from 'components/Chip';

import SwimlaneLayout from './SwimlaneLayout';
import { budgetMenu, userActivity } from 'utils/constants';
import {
  formatCurrency,
  formatBudgetScreenDateTime,
  stopPropagation,
  toastStyle,
} from 'utils/helper';
import { useAppContext } from 'contexts/AppContext';
import FormInput from 'components/FormInput';

import './styles.scss';
import { useNavigate } from 'react-router-dom';
import ALL_ROUTES from 'config/routes';

const BudgetScreen = () => {
  const {
    isFetchingBudgets,
    budgetList,
    budgetListData,
    selectedBudget,
    setSelectedBudget,
    contextualSuggestions,
    projectTotal,
    setOpenTabsList,
    openTabsList,
    showCreateBudgetModal,
    setNewBudgetIdFunc,
    showShareBudgetModal,
    changeTab,
    selectTab,
    renameTab,
    collaboratorsList,
    isBudgetOwner,
    menuPermissions,
    budgetData,
    menuActions,
    hasEditAccess,
    createBudgetMutation,
    refetchBudgetId,
    refetchBudgets,
    refetchTabs,
    addedContextualSuggestions,
    setAddedContextualSuggestions,
    closeTabsMutation,
    openTabsListInitial,
    selectTabFromDropdown,
    createTabsMutation,
    getbudgetDropdownData,
    useTabsQueries,
    fringeApplied,
  } = useHomeScreenContext();
  const { userData, userActivityMutation } = useAppContext();
  const queryClient = useQueryClient();
  const [editTab, setEditTab] = useState('');
  const swimlaneContainer = useRef();
  const [collaborators, setCollaborators] = useState();
  const [newTabName, setNewTabName] = useState('');
  const [budgetDropdowndata, setBudgetDropdowndata] = useState([]);
  const activeUser = '';
  const hasBudgets = openTabsList?.length > 0;
  const hanBudgetsInMyBudgets = budgetList?.length > 0;
  const navigate = useNavigate();
  const swimlaneData = queryClient.getQueryData([
    'get-swimlanes-in-budget',
    { id: selectedBudget?.budget, type: 'swimlane' },
  ]);
  const swimlaneItems = swimlaneData?.data;
  const contextualSuggestionsRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (
      collaboratorsList?.registered_collaborators?.length ||
      collaboratorsList?.invited_collaborators?.length
    ) {
      let collaboratorUsers =
        !isBudgetOwner() && collaboratorsList?.budget_owner
          ? [
              {
                id: collaboratorsList?.budget_owner?.id,
                name:
                  (collaboratorsList?.budget_owner?.name.length > 1 &&
                    collaboratorsList?.budget_owner?.name) ||
                  collaboratorsList?.budget_owner?.email,
                // email: collaboratorsList?.budget_owner?.email,
                backgroundColor: randomColor(),
              },
            ]
          : [];
      collaboratorUsers = [
        ...collaboratorUsers,
        ...(collaboratorsList?.registered_collaborators
          ?.filter(user => user?.collaborator_user_email !== userData?.email)
          ?.map(user => {
            return {
              backgroundColor: randomColor(),
              name: user?.collaborator_name,
              email: user?.collaborator_user_email,
              id: user?.id,
              image: user?.collaborator_profile_image,
            };
          }) || []),
        ...(collaboratorsList?.invited_collaborators
          ?.filter(user => user?.collaborator_email !== userData?.email)
          ?.map(user => {
            return {
              backgroundColor: randomColor(),
              name: user?.collaborator_name,
              email: user?.collaborator_email,
              id: user?.id,
            };
          }) || []),
      ];
      setCollaborators(collaboratorUsers);
    } else {
      setCollaborators([]);
    }
  }, [collaboratorsList, userData, isBudgetOwner]);
  console.log('collaborators', collaborators);
  useEffect(() => {
    contextualSuggestionsRef?.current?.scrollTo({
      left: scrollPosition,
      top: 0,
      behavior: 'smooth',
    });
  }, [scrollPosition]);

  const handleRenameBudget = async () => {
    if (!newTabName?.length || editTab?.name === newTabName) {
      setNewTabName('');
      disableEditTab();
      return false;
    }

    try {
      const response = await createBudgetMutation.mutateAsync({
        name: newTabName,
        id: editTab?.budget,
      });
      const newBudget = response?.data;
      newBudget?.id && renameTab(newBudget.id, newBudget);
      refetchBudgets?.();
      refetchBudgetId?.();
      // setNewTabName('');
      disableEditTab();
      toast.success(
        `Budget "${editTab?.name}" renamed to "${newTabName}" successfully`,
        toastStyle
      );
    } catch (error) {
      const message =
        error?.response?.data?.name?.[0] ||
        error?.response?.data?.non_field_errors?.[0];
      toast.error(
        message ||
          `An error occurred while renaming budget. Please try again later`,
        toastStyle
      );
    }
  };

  const handleCreateTabs = async budget_id => {
    const payload = [];
    payload?.push({
      budget: budget_id,
    });
    try {
      const response = await createTabsMutation.mutateAsync(payload);
      const newTabs = response?.data;
      refetchTabs();
    } catch (error) {
      console.log('Error in tabs creation', error);
    }
  };

  const budgetListMenu = useMemo(() => {
    setBudgetDropdowndata(getbudgetDropdownData);
    return getbudgetDropdownData;
  }, [getbudgetDropdownData]);

  const handleTabActions = tab => action => {
    if (action === 'close-budget-tab') {
      // closeBudgetsData?.push(selectedBudget);
      handleCloseTabs(selectedBudget);
    } else {
      return menuActions[action]?.();
    }
  };

  const handleCloseTabs = async tab => {
    try {
      const response = await closeTabsMutation.mutateAsync(tab?.id);
      refetchBudgets();
      refetchTabs();
    } catch (error) {
      console.log('Error in closing of tabs', error);
    }
  };

  const handleContextualSuggestionClick = item => () => {
    userActivityMutation.mutate({
      activity_type: userActivity.BUDGET_EXPORTED,
    });
    setAddedContextualSuggestions(prev => ({ ...prev, [item.name]: true }));
  };

  const handleCreateBudget = () => {
    setNewBudgetIdFunc(true);
    showCreateBudgetModal();
  };
  const renderNewBudgetTab = () => {
    return (
      <div
        className="budget-tab budget-tab-new-budget"
        onClick={() => handleCreateBudget()}
      >
        <AddIcon className="budget-tab__add-icon" />
        New Budget
      </div>
    );
  };

  const enableEditTab = budget => setEditTab(budget);
  const disableEditTab = () => setEditTab(null);

  const handleTabClick = budget => e => {
    if (isBudgetOwner()) {
      stopPropagation(e);
      enableEditTab(budget);
    }
  };

  const handleTabChange = budget => () => {
    changeTab(budget, true);
    budget?.id !== editTab?.id && disableEditTab();
  };

  const handleTabNameChange = event => {
    const value = event.target.value;
    switch (event.key || event.type) {
      case 'Enter':
      case 'blur':
        event.preventDefault();
        handleRenameBudget();
        break;
      case 'Escape':
      case 'Tab':
        setNewTabName('');
        disableEditTab();
        event.preventDefault();
        break;
      default:
        return setNewTabName(value);
    }
    // window?.location?.reload();
  };

  const renderBudgetTab = () => {
    return (
      <div className="budget-tabs">
        {hasBudgets ? (
          <>
            {openTabsList?.map(budget => {
              const activeClass =
                selectedBudget?.budget === budget?.budget
                  ? 'budget-tab-active'
                  : '';
              return (
                <div
                  className={`budget-tab ${activeClass}`}
                  key={`budget-tab-${budget?.budget}`}
                  onClick={handleTabChange(budget)}
                >
                  {editTab?.budget === budget?.budget ? (
                    <FormInput
                      defaultValue={budget?.name}
                      onChange={handleTabNameChange}
                      onKeyDown={handleTabNameChange}
                      onBlur={handleTabNameChange}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={activeClass ? handleTabClick(budget) : undefined}
                    >
                      {budget?.name?.length > 44
                        ? budget?.name?.slice(0, 44)?.concat('..')
                        : budget?.name}
                    </span>
                  )}
                  <DropDownMenu
                    toggleIcon={
                      <ExpandIcon className="budget-tab__expand-icon" />
                    }
                    onChange={handleTabActions(budget)}
                    onClick={stopPropagation}
                    menuOptions={
                      budgetMenu?.filter(
                        option => menuPermissions[option.value] ?? true
                      ) || []
                    }
                  />
                </div>
              );
            })}
            {budgetListMenu?.length > 0 && (
              <DropDownMenu
                className="budgets-dropdown"
                menuOptions={budgetListMenu}
                onChange={selectTabFromDropdown}
                align={{ sm: 'end' }}
                toggleLabel={`+ ${budgetListMenu?.length} more`}
              />
            )}
            {renderNewBudgetTab()}
          </>
        ) : (
          renderNewBudgetTab()
        )}
      </div>
    );
  };

  const renderCollaborators = () => {
    return (
      hasBudgets && (
        <div className="collaborators">
          {collaborators?.slice(0, 4)?.map((user, index) => {
            const activeClass =
              user.name === activeUser?.name ? 'collaborators-icon-active' : '';
            return (
              <Avatar
                key={`${index}-${user.id}`}
                name={
                  user.name != null &&
                  user.email != undefined &&
                  user.name.length > 1
                    ? `${user.name.split(' ').slice(0, 2).join(' ')} (${
                        user?.email
                      })`
                    : user.name != null && user.email == undefined
                    ? user.name
                    : user.email
                }
                imageStyle={{ backgroundColor: user.backgroundColor }}
                className={`collaborators-icon ${activeClass}`}
                image={
                  user?.image && <img src={user?.image} alt={user?.name} />
                }
              />
            );
          })}
          {collaborators?.length > 4 && (
            <div className="collaborators-expand">
              <DropDownMenu
                className="collaborators-dropdown"
                menuOptions={collaborators
                  ?.slice(4)
                  ?.map(user => ({ label: user.name, value: user.id }))}
              />
            </div>
          )}
          <div className="share-budget-button-wrapper">
            <Button
              label={collaborators?.length > 0 ? 'Sharing' : 'Share Budget'}
              type="button"
              className="share-budget-button"
              variant="primary"
              onClick={showShareBudgetModal}
            />
          </div>
        </div>
      )
    );
  };

  const renderNoBudgetScreen = () => {
    return (
      <div className="no-budgets-card col-md-4">
        <h3 className="card-header">Lets Get Started!</h3>
        <div className="card-body">
          {hanBudgetsInMyBudgets ? (
            <span>
              You don&apos;t have any budgets open at the moment. Create or open
              a budget to get started.
            </span>
          ) : (
            <span>
              You don&apos;t have any existing budgets at the moment. Create a
              budget to get started.
            </span>
          )}
        </div>
        <div className="card-action">
          {/*
          <Button
            label="Import Budget"
            type="button"
            className="import-budget-button"
            variant="primary"
          />
          */}
          <Button
            label="New Budget"
            type="button"
            className="create-budget-button"
            variant="primary"
            onClick={showCreateBudgetModal}
          />
          {hanBudgetsInMyBudgets && (
            <Button
              label="Open Budget"
              type="button"
              className="create-budget-button"
              variant="primary"
              onClick={() => {
                navigate(ALL_ROUTES.BUDGET_DETAILS);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const handleBackClick = () => {
    if (scrollPosition > 0) {
      const position = scrollPosition - 350;
      setScrollPosition(scrollPosition < 0 ? 0 : position);
    }
  };

  const handleFrowardClick = () => {
    const scrollWidth = contextualSuggestionsRef?.current?.scrollWidth;
    if (scrollPosition < scrollWidth) {
      const position = scrollPosition + 350;
      setScrollPosition(scrollPosition > scrollWidth ? scrollWidth : position);
    }
  };

  const renderContextualSuggestions = () => {
    const suggestions = contextualSuggestions?.filter(suggestionItem => {
      return !(
        addedContextualSuggestions[suggestionItem?.name] ||
        swimlaneItems?.some(
          item =>
            item?.name?.toLowerCase() === suggestionItem?.name?.toLowerCase()
        )
      );
    });
    return (
      <div className="contextual-suggestions">
        <div className="contextual-suggestions__title">Suggestions:</div>
        <div className="contextual-suggestions__container">
          <ArrowBackIcon
            className={`back-icon ${scrollPosition > 0 ? '' : 'disabled'}`}
            onClick={handleBackClick}
          />
          <div
            className="contextual-suggestions__container__chip-wrapper"
            ref={contextualSuggestionsRef}
          >
            {suggestions?.map(item => {
              return (
                <Chip
                  key={item?.id}
                  className="suggestions-chip"
                  title={item?.name}
                  icon={<AddIcon />}
                  onClick={handleContextualSuggestionClick(item)}
                  disabled={!hasEditAccess()}
                />
              );
            })}
          </div>
          <ArrowForwardIcon
            className={`forward-icon ${
              scrollPosition < contextualSuggestionsRef?.current?.scrollWidth
                ? ''
                : 'disabled'
            }`}
            onClick={handleFrowardClick}
          />
        </div>
      </div>
    );
  };

  const renderTotal = () => {
    const lastEditedBy =
      budgetData?.last_edited_by || budgetData?.created_by_name;
    const lastEditDateTime = budgetData?.updated_on || budgetData?.created_on;
    return (
      <div className="budget-total-wrapper">
        <Chip
          fringe={
            <div>
              {fringeApplied > 0 && (
                <div
                  className="fringe-total"
                  style={{
                    fontSize: '12px',
                    color: '#888',
                  }}
                >
                  Fringe Total: {formatCurrency(fringeApplied)}{' '}
                </div>
              )}
            </div>
          }
          className="budget-total"
          title={`Project Total: ${formatCurrency(
            projectTotal || 0,
            selectedBudget?.primary_currency
          )}`}
        />

        {/* Hardcoded Fringe Total (Shown only if fringes exist) */}
        <div className="budget-modified">
          Last updated by{' '}
          <span className="budget-modified-by">{lastEditedBy}</span> on{' '}
          {formatBudgetScreenDateTime(lastEditDateTime)}
        </div>
      </div>
    );
  };

  return (
    <div className="budget-container pe-5">
      <div className="budget-tabs-wrapper">
        {renderBudgetTab()}
        {renderCollaborators()}
      </div>
      <div
        className={`budget-tab-content ${hasBudgets ? '' : 'no-budgets'}`}
        ref={swimlaneContainer}
      >
        {!hasBudgets && renderNoBudgetScreen()}
        {hasBudgets && selectedBudget?.budget && (
          <SwimlaneLayout
            swimlaneHeight={swimlaneContainer?.current?.offsetHeight}
            budgetId={selectedBudget?.budget}
            key={selectedBudget?.budget}
          />
        )}
      </div>
      {hasBudgets && selectedBudget?.budget && (
        <div className="budget-container__footer">
          {renderContextualSuggestions()}
          {renderTotal()}
        </div>
      )}
      {isFetchingBudgets && !budgetList?.length && <GridLoader />}
    </div>
  );
};

export default BudgetScreen;
