import React from 'react';
import { toast } from 'react-toastify';

import DeleteConfirmModal from 'components/DeleteConfirmModal';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { setSelectedTabInCache } from 'utils/common';
import PropTypes from 'prop-types';
import { toastStyle } from 'utils/helper';

const DeleteFringes = props => {
  const {
    refetchFringe,
    showDeleteFringe,
    refetchTabs,

    delData,
    hideDeleteFringeModal,
    deleteFringeMutation,
  } = useHomeScreenContext();

  const handleCancel = () => {
    // hideDeleteMultiBudgetModal();
    hideDeleteFringeModal();
  };

  const getDeleteItemCount = () => {
    return delData?.reduce((i, n) => {
      return i + 1;
    }, 0);
  };
  const generateDeleteMessage = () => {
    const fringeCount = getDeleteItemCount();
    return `Are you sure you want to delete ${fringeCount} ${
      fringeCount === 1 ? 'file' : 'files'
    }`;
  };

  const deleteMessage =
    delData?.length === 1
      ? `Are you sure you want to delete ${delData?.[0]?.full_name}?`
      : generateDeleteMessage();

  const handleDeleteBudget = async () => {
    const fringeIds = [];
    for (let i = 0; i < delData?.length; i++) {
      const id = Number(delData[i]?.id);
      fringeIds.push(id);
    }
    try {
      console.log('fringeIds', fringeIds);
      await deleteFringeMutation.mutateAsync({
        fringe_Ids: fringeIds,
      });
      toast.dismiss();
      toast.success(
        `Budget "${delData[0]?.full_name}" deleted successfully`,
        toastStyle
      );
      handleCancel();
      refetchFringe?.();
      refetchTabs();
      props?.onFringeCreated?.();
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        error?.response?.data?.non_field_errors?.[0];
      toast.dismiss();
      toast.error(
        message ||
          `An error occurred while deleting the budget "${delData[0]?.full_name}". Please try again later`,
        toastStyle
      );
    }
  };

  return (
    <DeleteConfirmModal
      showModal={showDeleteFringe}
      handleOnClose={handleCancel}
      onCancel={handleCancel}
      onConfirm={handleDeleteBudget}
      confirmText={deleteMessage}
      confirmLabel="Delete"
    />
  );
};
DeleteFringes.propTypes = {
  refetch: PropTypes.func.isRequired,
  onFringeCreated: PropTypes.func.isRequired,
};

export default DeleteFringes;
