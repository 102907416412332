import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import PropTypes from 'prop-types';
import { toastStyle } from 'utils/helper';

const EditGroup = props => {
  const {
    UpdateGroupsMutation,
    refetchGroup,
    hideUpdateGroupModal,
    selectTab,
    clearBudgetRename,
  } = useHomeScreenContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: props?.GroupName || '',
      id: props?.GroupId,
      fringe: props?.fringe || '',
    },
  });

  const [removedFringes, setRemovedFringes] = useState([]);
  const fringeList =
    props?.GroupFringes?.split(' ')?.filter(f => f.trim() !== '') || [];

  const handleCreateBudget = handleSubmit(async data => {
    try {
      const response = await UpdateGroupsMutation.mutateAsync({
        ...data,
        id: props?.GroupId,
        remove_fringe: removedFringes,
      });
      const newBudget = response?.data;
      if (newBudget?.id) selectTab(newBudget.id, newBudget);
      refetchGroup?.();
      toast.success(`Budget updated successfully`, toastStyle);
      reset();
      handleCancel();
      props?.refetch();
    } catch (error) {
      const message =
        error?.response?.data?.template_type?.[0] ||
        error?.response?.data?.name?.[0] ||
        error?.response?.data?.non_field_errors?.[0] ||
        `An error occurred while updating budget. Please try again later`;
      toast.error(message, toastStyle);
    }
  });

  const handleCancel = () => {
    hideUpdateGroupModal();
    clearBudgetRename();
    reset();
    props?.closeFolder?.();
  };

  const handleSoftRemoveFringe = fringe => {
    setRemovedFringes(prev => [...prev, fringe]);
  };

  return (
    <Modal
      onHide={handleCancel}
      show={props?.showCreateGroupsModal}
      size="sm"
      className="create-budget"
      bodyClass="create-budget__body"
      centered
    >
      <h1 className="create-budget__body__header">
        Edit Groups{' '}
        {watch('name') && (
          <span className="budget-name">&ldquo;{watch('name')}&rdquo;</span>
        )}
      </h1>
      <Form className="create-budget__body__form" onSubmit={handleCreateBudget}>
        <FormInput
          label="Group Name"
          type="text"
          error={errors?.name?.message}
          placeholder="Please enter a name"
          {...register('name', { required: 'Group name is required' })}
        />
        <div className="fringe-list">
          {fringeList.length > 0 && (
            <ul
              style={{
                listStyle: 'none',
                paddingLeft: 0,
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {fringeList
                .filter(fringe => !removedFringes.includes(fringe))
                .map((fringe, index) => (
                  <li
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span>{fringe}</span>
                    <button
                      style={{
                        border: 'none',
                        background: 'transparent',
                        cursor: 'pointer',
                      }}
                      type="button"
                      onClick={() => handleSoftRemoveFringe(fringe)}
                    >
                      <Delete style={{ width: '40px', height: '40px' }} />
                    </button>
                  </li>
                ))}
            </ul>
          )}
        </div>
        <div className="create-budget__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="create-budget__action__cancel"
            variant="red-outlined"
            onClick={handleCancel}
          />
          <Button
            label="Confirm"
            className="create-budget__action__confirm"
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

EditGroup.propTypes = {
  GroupId: PropTypes.number,
  showCreateGroupsModal: PropTypes.bool,
  fringe: PropTypes.string,
  GroupFringes: PropTypes.string,
  GroupName: PropTypes.string,
  closeFolder: PropTypes.func,
  refetch: PropTypes.func.isRequired,
};

EditGroup.defaultProps = {
  showCreateGroupsModal: false,
  closeFolder: undefined,
  GroupId: '',
  GroupName: '',
  fringe: '',
  GroupFringes: '',
};

export default EditGroup;
