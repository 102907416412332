import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useBudgetMutation } from 'utils/api/mutations';
import { formatCurrency } from 'utils/helper';

import './styles.css';

function MultiSelectDropdown({
  groupFringeData,
  menuOptions,
  selectedValues, // Array of selected values there
  onChange,
  className,
  toggleClass,
  menuClass,
  toggleIcon,
  ItemRow,
}) {
  const [show, setShow] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [hoveredChip, setHoveredChip] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [isExpanded, setIsExpanded] = useState(false); // State to manage expansion

  // NEW: set up navigation
  const navigate = useNavigate();

  const { mutateAsync: fetchHoverBudget } =
    useBudgetMutation.useHoverBudgetMutation();

  const combinedOptions = useMemo(
    () => [...(groupFringeData || []), ...(menuOptions || [])],
    [groupFringeData, menuOptions]
  );

  const handleToggle = useCallback(nextShow => {
    setShow(nextShow);
    if (!nextShow) setShowClose(false);
  }, []);

  const handleSelect = useCallback(
    (eventKey, event) => {
      if (event?.stopPropagation) event.stopPropagation();
      const selectedOption = combinedOptions.find(
        opt => opt.value === eventKey
      );

      if (eventKey === 'navigate-somewhere') {
        navigate('/home/fringes-listing');
        return;
      }

      if (!selectedOption) return;

      const { value, label, type } = selectedOption;
      const alreadySelected = selectedValues.some(item => item.value === value);

      const updatedSelection = alreadySelected
        ? selectedValues.filter(item => item.value !== value)
        : [...selectedValues, { value, label, type }];

      onChange(updatedSelection);
    },
    [selectedValues, onChange, combinedOptions, navigate]
  );

  const handleRemove = value => {
    const updatedSelection = selectedValues.filter(
      item => item.value !== value
    );
    onChange(updatedSelection);

    if (hoveredChip && hoveredChip.value === value) {
      setHoveredChip(null);
    }
  };

  const handleMouseEnter = async (value, event) => {
    try {
      const selectedOption = combinedOptions.find(opt => opt.value === value);
      if (!selectedOption) return;

      const { type } = selectedOption;

      const hoverData = await fetchHoverBudget({
        item_total: ItemRow.item_total,
        fringe: value,
        type,
      });

      if (!hoverData || !hoverData.data.fringe_total) {
        console.warn(`No fringe_total found for: ${value}`);
        return;
      }

      const rect = event.target.getBoundingClientRect();

      const isFirstChip = selectedValues[0] === value && showClose;
      const leftPosition = isFirstChip
        ? rect.left + window.scrollX + 70
        : rect.left + window.scrollX;

      setPopupPosition({
        top: rect.top,
        left: leftPosition,
      });

      setHoveredChip({
        value,
        fringe_total: hoverData.data.fringe_total,
      });
    } catch (error) {
      console.error('Error fetching hover data:', error);
    }
  };

  const handleMouseLeave = () => {
    setHoveredChip(null);
  };

  const selectedChips = useMemo(() => {
    if (!isExpanded && selectedValues.length > 1) {
      const first = selectedValues[0];
      return [
        <span
          key={first.value}
          className="chip"
          onClick={() => setIsExpanded(true)}
          onMouseEnter={e => handleMouseEnter(first.value, e)}
          onMouseLeave={handleMouseLeave}
        >
          {first.label}
        </span>,
        selectedValues.length > 1 && (
          <span
            key="more"
            className="chip"
            onClick={() => setIsExpanded(true)}
            onMouseEnter={e => handleMouseEnter('more', e)}
            onMouseLeave={handleMouseLeave}
          >
            +{selectedValues.length - 1}
          </span>
        ),
      ];
    }

    return selectedValues.map(({ value, label, type }) => {
      const isGroupItem = groupFringeData.some(g => g.value === value);
      const chipClass = `chip ${isGroupItem ? 'group-chip' : 'menu-chip'}`;

      return (
        <span
          className={chipClass}
          key={value}
          onClick={() => setIsExpanded(true)}
          onMouseEnter={e => handleMouseEnter(value, e)}
          onMouseLeave={handleMouseLeave}
        >
          {label}
          {/* Always show the close button when expanded */}
          {isExpanded && (
            <button
              type="button"
              className="chip-close"
              onClick={e => {
                e.stopPropagation();
                handleRemove(value);
              }}
            >
              ✕
            </button>
          )}
        </span>
      );
    });
  }, [selectedValues, combinedOptions, isExpanded, groupFringeData]);

  return (
    <>
      {hoveredChip && (
        <div
          className="chip-popup"
          style={{
            position: 'fixed',
            top: popupPosition.top,
            left: popupPosition.left + 150,
            background: '#fff',
            padding: '10px 40px',
            border: '1px solid #ddd',
            borderRadius: '5px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,

            fontSize: '12px',
            whiteSpace: 'nowrap',
            textAlign: 'left',
          }}
        >
          {hoveredChip?.fringe_total[3] === 'group' ? (
            <>
              <strong
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                Group Name:
              </strong>{' '}
              {hoveredChip?.fringe_total[1]}
              <br />
              <br />
              {Object.entries(hoveredChip.fringe_total[2]).map(
                ([key, value]) => (
                  <div key={key}>
                    <strong>{key}:</strong> {formatCurrency(value.toFixed(2))}
                  </div>
                )
              )}
              <br />
              <strong>Group Total:</strong>{' '}
              {formatCurrency(hoveredChip?.fringe_total[0].toFixed(2))}
            </>
          ) : (
            <>
              <strong>Fringe Name:</strong> {hoveredChip.fringe_total[1]}
              <br />
              <strong>Fringe Total:</strong>{' '}
              {formatCurrency(hoveredChip.fringe_total[0].toFixed(2))}
            </>
          )}
        </div>
      )}

      <Dropdown
        className={`my-multi-dropdown ${className} ${
          showClose ? 'expanded' : ''
        }`}
        show={show}
        onToggle={handleToggle}
        onSelect={handleSelect}
      >
        <Dropdown.Toggle className={toggleClass}>
          <div className={`selected-chips ${showClose ? 'expanded' : ''}`}>
            {selectedChips.length ? selectedChips : 'Add'}
          </div>
          {selectedValues.length > 0 && ( // Show the button if there is at least one chip
            <button
              type="button"
              className="expand-button"
              onClick={() => setIsExpanded(!isExpanded)}
              style={{
                // marginLeft: '10px',
                padding: '5px 5px',
                border: '0px solid #ccc',
                background: 'transparent',
                color: '#0056b3',
                cursor: 'pointer',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {isExpanded ? 'Hide' : 'Show'}
            </button>
          )}
          <div>{selectedChips.length > 0 ? 'Add' : ''}</div>
          {toggleIcon || <span className="default-icon">+</span>}
        </Dropdown.Toggle>

        <Dropdown.Menu className={`my-multi-dropdown-menu ${menuClass}`}>
          {combinedOptions.map(option => {
            if (option.value === 'divider') return null;

            const isActive = selectedValues.some(
              item => item.value === option.value
            );

            return (
              <Dropdown.Item
                key={option.value}
                eventKey={option.value}
                active={isActive}
                as="div"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  padding: '8px',
                  overflow: 'hidden',
                }}
              >
                <input
                  type="checkbox"
                  checked={isActive}
                  readOnly
                  className="checkbox-style"
                  style={{
                    width: '14px',
                    height: '14px',
                    cursor: 'pointer',
                  }}
                />
                <span
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {option.label}
                </span>
              </Dropdown.Item>
            );
          })}
          <Dropdown.Item
            as="button"
            eventKey="navigate-somewhere"
            className="dropdown-add-item"
            style={{
              fontWeight: 'bold',
            }}
          >
            <span
              className="plus-icon"
              style={{
                position: 'relative',
                right: '3px',
                fontSize: '20px',
              }}
            >
              +
            </span>
            <span
              style={{
                marginLeft: '2px',
              }}
            >
              Add
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {/* Add the "Show" / "Hide" button */}
    </>
  );
}

MultiSelectDropdown.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  groupFringeData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  className: PropTypes.string,
  toggleClass: PropTypes.string,
  menuClass: PropTypes.string,
  toggleIcon: PropTypes.node,
  ItemRow: PropTypes.arrayOf(PropTypes.node).isRequired,
};

MultiSelectDropdown.defaultProps = {
  groupFringeData: [],
  selectedValues: [],
  onChange: () => null,
  className: '',
  toggleClass: '',
  menuClass: '',
  toggleIcon: null,
};

export default MultiSelectDropdown;
