import React from 'react';
import PropTypes from 'prop-types';
import './ToggleButton.scss';

const ToggleButtons = ({ options, active, onToggle }) => {
  return (
    <div className="fringes-groups-parent">
      <div className="fringe-group-toggle">
        {options.map(option => (
          <button
            key={option}
            className={`fringe-group ${
              active === option.toLowerCase() ? 'active' : ''
            }`}
            onClick={() => onToggle(option.toLowerCase())}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

ToggleButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  active: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ToggleButtons;
