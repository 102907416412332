import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for props validation
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import 'components/CreateGroups/styles.scss';
import { useGroupMutation } from 'utils/api/mutations'; // Add the fetchGroups function

const CreateGroups = props => {
  const [showValueInput, setShowValueInput] = useState(false); // State to toggle value input
  const [groupOptions, setGroupOptions] = useState([]); // State for groups
  const createGroupMutation = useGroupMutation.useCreateGroupMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const onSubmit = data => {
    // Build the payload JSON step by step
    console.log('Form Data:', data);
    const payload = {};
    payload.name = data.name;
    console.log('Final Payload:', payload);

    // Mutate using the created payload
    createGroupMutation.mutate(payload, {
      onSuccess: () => {
        props?.closeFolder();
        reset();
      },
      onError: error => {
        console.error('Mutation error:', error);
      },
    });
  };

  return (
    <Modal
      onHide={props?.closeFolder}
      show={props?.showCreateGroupsModal}
      size="sm"
      className="create-budget"
      bodyClass="create-budget__body"
      centered
    >
      <h1 className="create-budget__body__header">Add New Fringe</h1>
      <Form className="overflow-hidden" onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Group Name"
          type="text"
          error={errors?.name?.message}
          placeholder="Please enter a name"
          {...register('name', { required: 'Fringe name is required' })}
        />
        <div className="create-budget__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="create-budget__action__cancel"
            variant="red-outlined"
            onClick={props?.closeFolder}
          />
          <Button
            label="Create"
            className="create-budget__action__confirm"
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

// Define propTypes for props validation
CreateGroups.propTypes = {
  closeFolder: PropTypes.func.isRequired,
  showCreateGroupsModal: PropTypes.bool.isRequired,
};

export default CreateGroups;
