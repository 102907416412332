import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
import CreateBudget from 'components/CreateBudget';
import EditBudget from 'components/EditBudget';
import AddFolder from 'components/AddFolder';
import { useAppContext } from 'contexts/AppContext';
import { useHomeScreenContext } from './contexts/HomeScreenContext';
import DeleteBudget from 'components/DeleteBudget';
import ShareBudget from 'components/ShareBudget';
import BudgetSettings from 'components/BudgetSettings';
import LogoutModal from 'pages/HomeScreens/AccountSettings/Logout';

import './styles.scss';
import DeleteMultiBudgets from 'components/DeleteMultiBudgets';
import DeleteFringes from 'components/DeleteFringe';
import DeleteGroup from 'components/DeleteGroup';
import GridLoader from 'components/GridLoader';

const HomeScreenLayout = () => {
  const { userData, userProfile } = useAppContext();
  const [loading, setLoading] = useState(false);
  const {
    menuActions,
    showCreateBudget,
    showDeleteBudget,
    showDeleteFringe, //fringe
    showCreateFolder,
    showShareBudget,
    menuPermissions,
    showBudgetSettings,
    showLogOutModal,
    hideLogOutModal,
    logOutModalShow,
    budgetList,
    selectTab,
    showDeleteMultiBudgets,
    showDeleteGroup,
    showEditBudget,
  } = useHomeScreenContext();

  const handleLoader = value => {
    console.log('value', value);
    setLoading(value);
  };

  return (
    <div className="home-screen-wrapper">
      <Header
        menuActions={menuActions}
        menuPermissions={menuPermissions}
        userData={
          userData
            ? {
                ...userData,
                profile_image: userProfile?.userprofile?.profile_image,
                name: userProfile?.name,
              }
            : {}
        }
        logOutModalShow={logOutModalShow}
        budgetList={budgetList}
        selectTab={selectTab}
        handleLoader={handleLoader}
      />
      <main className="main-container ps-5 pt-2">
        {loading ? <GridLoader /> : <Outlet />}
      </main>
      {showCreateBudget && <CreateBudget />}
      {showEditBudget && <EditBudget />}
      {showCreateFolder && <AddFolder />}
      {/* {showDeleteMultiBudgets && <DeleteMultiBudgets />} */}

      {showDeleteFringe && <DeleteFringes />}
      {showDeleteGroup && <DeleteGroup />}
      {showDeleteBudget && <DeleteBudget />}
      {showShareBudget && <ShareBudget />}
      {showBudgetSettings && <BudgetSettings />}
      {showLogOutModal && (
        <LogoutModal
          hideLogOutModal={hideLogOutModal}
          showLogOutModal={showLogOutModal}
        />
      )}
    </div>
  );
};

export default HomeScreenLayout;
