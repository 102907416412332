import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';

import './styles.scss';
import { prepareDropDownOptions } from 'utils/common';
import { toastStyle } from 'utils/helper';

const EditBudget = () => {
  const {
    createBudgetMutation,
    refetchBudgets,
    budgetTypes,
    showEditBudget,
    hideEditBudgetModal,
    renameBudget,
    selectTab,
    renameTab,
    clearBudgetRename,
    refetchBudgetId,
    refetchTabs,
    showNewBudgetId,
    createTabsMutation,
    makeAciveTabMutation,
    setWindowReload,
  } = useHomeScreenContext();
  const [budgetTypeOptions, setBudgetTypeOptions] = useState(
    prepareDropDownOptions(
      [{ name: 'Blank Budget (No Template)', id: '' }, ...budgetTypes],
      'name',
      'id'
    )
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: renameBudget?.name
        ? renameBudget?.name
        : renameBudget?.full_name
        ? renameBudget?.full_name
        : '',
      template_type: renameBudget?.template_type?.toString() || '',
    },
  });

  useEffect(() => {
    setBudgetTypeOptions(
      prepareDropDownOptions(
        [{ name: 'Blank Budget (No Template)', id: '' }, ...budgetTypes],
        'name',
        'id'
      )
    );
  }, [budgetTypes]);

  const handleCreateBudget = () =>
    handleSubmit(async data => {
      try {
        const response = await createBudgetMutation.mutateAsync({
          ...data,
          template_type: data?.template_type?.value,
          id: renameBudget?.id,
        });
        const newBudget = response?.data;
        if (renameBudget?.id) {
          newBudget?.id && renameTab(newBudget.id, newBudget);
          refetchBudgets?.();
          refetchBudgetId?.();
          toast.success(
            `Budget "${renameBudget?.name}" renamed to "${newBudget?.name}" successfully`,
            toastStyle
          );
          handleCancel();
          return;
        }
        // newBudget?.id && selectTab(newBudget.id, newBudget);
        // refetchBudgetId?.();
        await handleCreateTabs(newBudget?.id);
        toast.success(
          `Budget "${data?.name}" ${
            renameBudget?.id ? 'updated' : 'created'
          } successfully`,
          toastStyle
        );
        // refetchBudgets?.();
        // refetchTabs();
        reset();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        handleCancel();
      } catch (error) {
        const message =
          error?.response?.data?.template_type?.[0] ||
          error?.response?.data?.name?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.error(
          message ||
            `An error occurred while ${
              renameBudget?.id ? 'updating' : 'creating'
            } budget. Please try again later`,
          toastStyle
        );
      }
    });

  const handleCreateTabs = async budget_id => {
    const payload = [];
    payload?.push({
      budget: budget_id,
    });
    try {
      const response = await createTabsMutation.mutateAsync(payload);
      await makeAciveTabMutation.mutateAsync(response?.data?.[0]?.id);
      const newTabs = response?.data;
      await refetchTabs();
    } catch (error) {
      console.log('Error in tabs creation', error);
    }
  };

  const handleCancel = () => {
    hideEditBudgetModal();
    clearBudgetRename();
    reset();
  };

  return (
    <Modal
      onHide={handleCancel}
      show={showEditBudget}
      size="sm"
      className="create-budget"
      bodyClass="create-budget__body"
      centered={true}
    >
      <h1 className="create-budget__body__header">
        {renameBudget?.id ? 'Edit' : 'Create'} Budget{' '}
        {watch('name') && (
          <span className="budget-name">&ldquo;{watch('name')}&rdquo;</span>
        )}
      </h1>
      <Form
        className="create-budget__body__form"
        onSubmit={handleCreateBudget()}
      >
        <FormInput
          label="Budget Title"
          type="text"
          error={errors?.name?.message}
          placeholder="Please enter a budget title"
          {...register('name', { required: 'Budget title is required' })}
        />
        <div className="create-budget__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="create-budget__action__cancel"
            variant="red-outlined"
            onClick={handleCancel}
          />
          <Button
            label={renameBudget?.id ? 'Confirm' : 'Create'}
            className="create-budget__action__confirm"
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default EditBudget;
