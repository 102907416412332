import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import MotionPictureBudget from './MotionPictureBudget';
import Button from 'components/Button';
import { ReactComponent as PrinterIcon } from 'assets/images/printer-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/download-icon.svg';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { ReactComponent as ArrowBack } from 'assets/images/arrow-back.svg';
import FormCheckbox from 'components/FormCheckbox';
import AicpBudgetData from './AicpBudget';
import { useAppContext } from 'contexts/AppContext';
import { userActivity } from 'utils/constants';
import { useBudgetQueries } from 'utils/api/queries';

import './styles.scss';
import { toastStyle } from 'utils/helper';
import { set } from 'react-hook-form';

const PrintBudget = () => {
  const componentRef = useRef(null);
  const [uniqId, setUniqId] = useState(new Date()?.valueOf());
  const pdfGeneratorRef = useRef();
  const { selectedBudget, getBudgetType, printShow, setPrintShow } =
    useHomeScreenContext();
  const { userActivityMutation } = useAppContext();
  const [includeFringeDetails, setIncludeFringeDetails] = useState(true);

  const toggleFringeDetails = () =>
    setIncludeFringeDetails(!includeFringeDetails);
  const { data, isLoading } = useBudgetQueries.useBudgetDataQuery({
    budgetId: selectedBudget?.budget,
    enabled: Boolean(selectedBudget?.budget),
  });
  const budgetData = data?.data;

  const [includeComments, setIncludeComments] = useState(true);
  const navigate = useNavigate();
  const [includeProductionDetails, setIncludeProductionDetails] =
    useState(true);

  const selectedTemplate = selectedBudget?.template_type?.toString() || '2';

  useEffect(() => {
    setUniqId(new Date().valueOf());
  }, [includeComments, includeProductionDetails, budgetData]);

  const handleAfterPrint = React.useCallback(
    activity => () => {
      if (activity === userActivity.BUDGET_PRINTED) {
        userActivityMutation.mutate({
          activity_type: userActivity.BUDGET_PRINTED,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleBeforePrint = React.useCallback(() => {}, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    toast.loading('Loading....');

    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
        toast.dismiss();
      }, 2000);
    });
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button
        label={
          <div className="button-label">
            <PrinterIcon /> Print
          </div>
        }
        type="button"
        className="print-budget-button"
        variant="primary"
      />
    );
  }, []);

  const budgetType = getBudgetType();

  useEffect(() => {
    if (printShow && pdfGeneratorRef.current) {
      const timer = setTimeout(() => {
        handleDownload();
      }, 3700);
      return () => clearTimeout(timer);
    }
  }, [printShow, pdfGeneratorRef.current]);

  useEffect(() => {
    if (printShow) {
      setTimeout(() => {
        toast.loading('PDF export is being generated, one moment…');
      }, 1000);
    }
    toast.dismiss();
  }, [printShow]);

  const handleDownload = async iframe => {
    if (!pdfGeneratorRef.current) {
      toast.error('PDF generator not initialized.', toastStyle);
      return;
    }

    try {
      toast.dismiss();
      toast.loading('Loading....');

      await pdfGeneratorRef.current.download(
        `${budgetData?.name}${budgetType ? '-' + budgetType : ''}.pdf`
      );
      toast.dismiss();
      userActivityMutation.mutate({
        activity_type: userActivity.BUDGET_EXPORTED,
      });
    } catch (error) {
      const message =
        error?.response?.data?.non_field_errors?.[0] ||
        error?.response?.data?.message?.non_field_errors?.[0];
      toast.dismiss();
      toast.error(
        message || 'An error occurred while downloading the budget',
        toastStyle
      );
    }
    setPrintShow(false);
  };

  const toggleComments = () => setIncludeComments(!includeComments);

  const toggleProductionDetails = () =>
    setIncludeProductionDetails(!includeProductionDetails);

  const goBack = () => {
    navigate(-1);
  };

  const getLabel = label => {
    switch (label) {
      case 'Multiplier Unit':
        return 'X Unit';
      // case 'Comments':
      //   return 'Note';
      case 'Quantity':
        return 'Qty';
      case 'Multiplier':
        return 'X';
      default:
        return label;
    }
  };

  return (
    <div className="print-layout-container">
      <div className="print-actions px-5 ">
        <ArrowBack onClick={goBack} className="print-actions__back-icon" />
        <Button
          label={
            <div className="button-label">
              <DownloadIcon /> Download
            </div>
          }
          type="button"
          className="download-budget-button"
          variant="primary"
          onClick={handleDownload}
        />
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle={`${selectedBudget?.name}-${
            budgetType ? '-' + budgetType : ''
          }`}
          onAfterPrint={handleAfterPrint(userActivity.BUDGET_PRINTED)}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
      </div>
      <div className="print-filters">
        <FormCheckbox
          label="Include Notes"
          checked={includeComments}
          onChange={toggleComments}
        />
        <FormCheckbox
          label="Include Production Information"
          checked={includeProductionDetails}
          onChange={toggleProductionDetails}
        />
        <FormCheckbox
          label="Include Fringes"
          checked={includeFringeDetails}
          onChange={toggleFringeDetails}
        />
      </div>
      {selectedTemplate === '1' && (
        <AicpBudgetData
          ref={componentRef}
          includeComments={includeComments}
          includeProductionDetails={includeProductionDetails}
          includeFringeDetails={includeFringeDetails} // Pass the state
          budgetData={budgetData}
          isLoading={isLoading}
          key={`AICP-${uniqId}`}
          pdfGeneratorRef={pdfGeneratorRef}
          getLabel={getLabel}
        />
      )}
      {selectedTemplate === '2' && (
        <MotionPictureBudget
          ref={componentRef}
          includeComments={includeComments}
          includeProductionDetails={includeProductionDetails}
          includeFringeDetails={includeFringeDetails} // Pass the state
          budgetData={budgetData}
          isLoading={isLoading}
          key={`motion-picture-${uniqId}`}
          pdfGeneratorRef={pdfGeneratorRef}
          getLabel={getLabel}
        />
      )}
    </div>
  );
};

export default PrintBudget;
