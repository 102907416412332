/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/formatDate';
import BudgetSearchInput from 'components/BudgetSearchInput';
import GridLoader from 'components/GridLoader';
import { Menu, MenuItem } from '@mui/material';
import { NavDropdown } from 'react-bootstrap';
import { formatCurrency } from 'utils/helper';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as NewFolder } from 'assets/images/new-folder.svg';
import { ReactComponent as ShowMore } from 'assets/images/down-arrow.svg';
import { ReactComponent as NewBudget } from 'assets/images/new-budget.svg';
import { ReactComponent as Selector } from 'assets/images/selector.svg';
import { ReactComponent as HighlightedSelector } from 'assets/images/highlighted-selector.svg';
import { ReactComponent as Search } from 'assets/images/search-icon.svg';
import BudgetIcon from 'assets/images/Budget.png';
import { ReactComponent as FolderIcon } from 'assets/images/folder-icon.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross-icon.svg';
import { ReactComponent as OpenInNew } from 'assets/images/open-in-new.svg';
import { ReactComponent as Download } from 'assets/images/download.svg';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';
import { ReactComponent as Share } from 'assets/images/share.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { ReactComponent as MoveIcon } from 'assets/images/move-icon.svg';
import { ReactComponent as MoveIcon2 } from 'assets/images/move-icon2.svg';
import { ReactComponent as GreaterArrow } from 'assets/images/greater-icon.svg';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import MoveBudgets from '../../components/MoveBudgets';
import { toast } from 'react-toastify';
import { useAppContext } from 'contexts/AppContext';
import { userActivity } from 'utils/constants';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';
import DeleteMultiBudgets from 'components/DeleteMultiBudgets';
import { useBudgetQueries, useTabsQueries } from 'utils/api/queries';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import AddFolder from 'components/AddFolder';
import EditFolder from 'components/EditFolder';
import CreateBudgetInsideFolder from 'components/CreateBudgetInsideFolder';
import EditBudgetInsideFolder from 'components/EditBudgetInsideFolder';
import DeleteFolder from 'components/DeleteFolder';

const BudgetDetails = () => {
  const { createTabsMutation } = useHomeScreenContext();
  const [searchTxt, setSearchTxt] = useState('');
  const [showMovePopup, setShowMovePopup] = useState(false);
  const [shouldCallApi, setShouldCallApi] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showNoAPi, setNoAPi] = useState(false);
  const [advSearch, setAdvsearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [parentId, setparentId] = useState(null);
  const [parentNames, setparentNames] = useState([]);
  const [products, setProducts] = useState([]);
  const [parentDetails, setParentDetails] = useState([]);
  const [showBudgetIds, setBudgetIds] = useState([]);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showEditFolder, setShowEditFolder] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [budgetName, setbudgetName] = useState('');
  const [folderId, setFolderId] = useState('');
  const [budgetId, setbudgetId] = useState(null);
  const [showBudgetFolder, setShowBudgetFolder] = useState(false);
  const [showEditBudgetFolder, setShowEditBudgetFolder] = useState(false);
  const [BudgetTypeData, setBudgetType] = useState('');
  const [loading, setLoading] = useState(true);

  const closeFolder = () => {
    setShowAddFolder(false);
    setShowEditFolder(false);
    setShowBudgetFolder(false);
    setShowEditBudgetFolder(false);
    setLoading(false);
  };

  const handleMovePopupClose = () => {
    setShowMovePopup(false);
    setSelectedRows([]);
    setparentNames([]);
    setparentId(null);
    setSearchQuery('');
    setAdvsearch(false);
    refetch();
  };

  const handleFolderClick = (type, data) => {
    let obj = {};
    if (type === 'Folder') {
      obj = {
        parent_id: data?.parent_id,
        folder_id: data?.id,
        type: data?.type,
      };
    } else {
      obj = {
        parent_id: data?.folder,
        folder_id: data?.id,
        type: data?.type,
      };
    }
    setParentDetails(prevDetails => [...prevDetails, obj]);
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const pdfGeneratorRef = useRef();

  const {
    data: budget_details,
    isLoading,
    refetch,
  } = useBudgetQueries.useBudgetDetailsDataQuery({
    payload: {
      adv_search: advSearch,
      search: searchQuery,
      parent_folder: parentId,
    },
    enabled: shouldCallApi,
  });
  const budgetData = budget_details?.data;

  //calling open tabsblist API
  const { data: tabsDetails } = useTabsQueries.useOpenTabsQuery({
    enabled: true,
  });
  const tabsData = tabsDetails?.data;

  useEffect(() => {
    // setLoading(true);
    if (!isLoading && budgetData) {
      // Assuming that budgetData has the necessary structure for your products state
      const newProducts = budgetData.map(budget => ({
        id: budget?.id,
        parent_id:
          budget?.type === 'Folder' ? budget?.parent_folder : budget?.folder,
        type: budget?.type,
        full_name: budget?.type === 'Folder' ? budget?.name : budget?.name,
        my_permission: budget?.my_permission || null,
        owner:
          budget?.type === 'Folder'
            ? budget?.created_by_name
            : budget?.created_by_name,
        created_date:
          budget?.type === 'Folder' ? budget?.created_on : budget?.created_on,
        last_updated:
          budget?.type === 'Folder' ? budget?.updated_on : budget?.updated_on,
        total: budget?.type === 'Folder' ? '' : budget?.total_cost,
        created_by_email: budget?.created_by_email,
        last_edited_by_this_collaborator:
          budget?.last_edited_by_this_collaborator,
        template_type: budget?.template_type,
        created_by_name: budget?.created_by_name || '',
        created_by_profile_image: budget?.created_by_profile_image,
        last_edited_by: budget?.last_edited_by || undefined,
        createdBy:
          budget?.type === 'Budget' ? budget?.created_by_profile_image : '',
        collaborators:
          (budget?.type !== 'Folder' &&
            budget?.collaborators?.map(collaborator => ({
              id: collaborator?.id,
              name: collaborator?.collaborator_name,
              email: collaborator?.collaborator_user_email,
              profileImage: collaborator?.collaborator_profile_image || '',
              lastEdited: collaborator?.last_edited_by_this_collaborator,
            }))) ||
          [],
      }));

      setProducts(newProducts);
      setLoading(false);
    }
  }, [budgetData]);

  console.log('budgets data', products);
  const { userActivityMutation } = useAppContext();
  const {
    budgetList,
    showShareBudgetModal,
    showCreateFolderModal,
    setSelectedDeldata,
    setFolderSelectedDeldata,
    showDeleteMultiBudgets,
    showDeleteFolder,
    showCreateBudgetModal,
    makeAciveTabMutation,
    getBudgetType,
    selectTab,
    openTabsList,
    setOpenTabsList,
    hasBudgetEditAccessInList,
    setBudgetToRename,
  } = useHomeScreenContext();
  const { userProfile } = useAppContext();
  // const [userDataTemp, setUserDataTemp] = useState([]);
  const [filteredBudgets, setFilteredBudgets] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const productsRef = useRef(products);
  const searchBarRef = useRef();

  const handleCreateTabs = async selRowsData => {
    const payload = [];
    for (let i = 0; i < selRowsData?.length; i++) {
      payload?.push({
        budget: selRowsData[i]?.id,
      });
    }
    try {
      const response = await createTabsMutation.mutateAsync(payload);
      const newTabs = response?.data;
      await makeAciveTabMutation.mutateAsync(newTabs[0]?.id);
      // setOpenTabsList([...tabsData, ...newTabs]);
      setOpenTabsList([...(tabsData || []), ...(newTabs || [])]);

      window.location.href = '/home';
    } catch (error) {
      console.log('Error in tabs creation', error);
    }
  };

  const handleCheckboxChange = e => {
    setAdvsearch(e?.target?.checked);
    setparentNames([]);
    setparentId(null);
  };

  const handleSearchChange = event => {
    const value = event?.target?.value;
    setSearchTxt(value);
  };

  const handleClearSearch = () => {
    setSearchTxt('');
    setSearchQuery('');
  };

  const handleCollaboratorCls = (collaborator, index, row) => {
    if (row?.original?.type === 'Folder') {
      return;
    }
    if (index === 0) {
      if (collaborator?.profileImage) {
        return 'first-collaborator-img';
      } else {
        return 'first-collaborator';
      }
    } else {
      return 'user-pic-wrapepr';
    }
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      const filteredList =
        searchTxt?.length &&
        budgetList?.filter(budget =>
          budget.name?.toLowerCase().includes(searchTxt?.toLowerCase())
        );

      setFilteredBudgets(filteredList || []);
      setSearchQuery(searchTxt);
    }
    // if (searchTxt !== '') {
    setparentNames([]);
    setparentId(null);
    // }
  };

  const data = useMemo(() => products, [products]);

  const handleSelectRow = (event, row) => {
    const isChecked = event?.target?.checked;
    // if (selectedRows?.length <= 3) {
    if (isChecked) {
      setSelectedRows(prevSelected => [...prevSelected, row.original]);
    } else {
      setSelectedRows(prevSelected =>
        prevSelected.filter(selectedRow => selectedRow.id !== row.original.id)
      );
    }
    // }
    if (!isChecked) {
      setSelectedRows(prevSelected =>
        prevSelected.filter(selectedRow => selectedRow.id !== row.original.id)
      );
    }
  };

  useEffect(() => {
    // Update the ref value whenever the products state changes
    productsRef.current = products;
  }, [products]);

  useEffect(() => {
    // Reset selectedRows when data changes
    setSelectedRows([]);
  }, [data]);

  useEffect(() => {
    // Your logic for handling selectedRows changes
  }, [selectedRows]);

  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <span
              className="header-txt"
              style={{ marginRight: '5px', marginLeft: '50px' }}
            >
              Budget Name
            </span>
            {/* <Search /> */}
          </>
        ),
        accessor: 'full_name',
        sticky: 'left',
        Cell: ({ row }) => (
          <div
            className={
              row?.original?.type === 'Folder'
                ? 'uname-cell cursorpointer'
                : 'uname-cell cursorpointer'
            }
          >
            <input
              type="checkbox"
              checked={selectedRows.some(
                selectedRow => selectedRow.id === row.original.id
              )}
              style={{
                cursor: 'pointer',
                width: '16px',
                height: '16px',
                margin: '0px 10px 0px 5px',
              }}
              onChange={event => {
                handleSelectRow(event, row);
              }}
              className="custom-checkbox"
            />
            {row.original.type === 'Folder' ? (
              <FolderIcon />
            ) : (
              <img
                src={BudgetIcon}
                alt="Budget"
                onClick={() => {
                  selectTab(row?.original?.id, row?.original);
                  handleCreateTabs([row?.original]);
                }}
              />
            )}
            &nbsp;
            <span
              className={
                row?.original?.type === 'Folder'
                  ? 'header-cell cursorpointer'
                  : 'header-cell cursorpointer'
              }
              style={{ marginRight: '10px' }}
              onClick={() => {
                handleFolderClick(row?.original?.type, row?.original);
                if (row?.original?.type === 'Folder') {
                  // setparentNames(prevParents => [
                  //   ...prevParents,
                  //   {
                  //     name: row?.original?.full_name,
                  //     parent_id: row?.original?.id,
                  //     id: row?.original?.parent_id,
                  //   },
                  // ]);
                  setparentNames(prevParents => {
                    // Check if the id already exists in the array
                    const idExists = prevParents.some(
                      parent => parent.id === row?.original?.parent_id
                    );

                    if (!idExists) {
                      // If the id doesn't exist, append the new object
                      return [
                        ...prevParents,
                        {
                          name: row?.original?.full_name,
                          parent_id: row?.original?.id,
                          id: row?.original?.parent_id,
                        },
                      ];
                    } else {
                      // If the id already exists, you can choose to update the existing object or ignore the new one
                      // For example, you might want to update the name property of the existing object:
                      return prevParents.map(parent => {
                        if (parent.id === row?.original?.parent_id) {
                          return {
                            ...parent,
                            name: row?.original?.full_name,
                          };
                        }
                        return parent;
                      });
                      // Alternatively, you can just return the existing state without any changes
                      // return prevParents;
                    }
                  });

                  setparentId(row?.original?.id);
                } else {
                  selectTab(row?.original?.id, row?.original);
                  handleCreateTabs([row?.original]);
                  // setOpenTabsList([row?.original]);
                  // window.location.href = '/home';
                }
              }}
            >
              {row.original.full_name}{' '}
            </span>
          </div>
        ),
        sortType: 'caseInsensitiveSort',
        disableSortBy: false,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        sticky: 'left',
        disableSortBy: true,
      },
      {
        Header: 'Created On',
        accessor: 'created_date',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ value }) => `${formatDate(value)}`,
      },
      {
        Header: 'Last Edited',
        accessor: 'last_updated',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ value }) => `${formatDate(value)}`,
      },
      {
        Header: 'Project Total',
        accessor: 'total',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ row }) =>
          row?.original?.type !== 'Folder'
            ? formatCurrency(row?.original.total || 0.0)
            : '--',
      },
      {
        Header: 'Collaborators',
        accessor: 'collaborators',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ row }) => {
          const collaborators = row?.original?.collaborators;

          if (collaborators) {
            const collaboratorList = row.original.collaborators;

            // const editedByCollaborator = !collaboratorList
            //   ? false
            //   : collaboratorList?.every(
            //       collaborator => !collaborator.lastEdited
            //     );
            let editedByCollaborator = false;
            for (let i = 0; i < collaboratorList.length; i++) {
              if (collaboratorList[i]?.lastEdited) {
                editedByCollaborator = true;
                break;
              }
            }
            const dropdownLength = editedByCollaborator === false ? 3 : 4;
            collaboratorList.sort((a, b) => {
              return a.lastEdited === true ? -1 : 1;
            });
            const userDataTemp = [];
            if (editedByCollaborator === false) {
              userDataTemp?.push({
                id: row?.original?.id,
                name: row?.original?.created_by_name,
                email: row?.original?.created_by_email,
                profileImage: row?.original?.created_by_profile_image || '',
                lastEdited: row?.original?.last_edited_by,
                collaboratorsExists: row?.original?.collaborator,
              });
            }
            let visibleCollaborators = [];
            let dropdownCollaborators = [];
            const prevdata = userDataTemp;
            const tempdata =
              userDataTemp?.length > 0
                ? collaboratorList.slice(0, 3)
                : collaboratorList.slice(0, 4);
            visibleCollaborators = [...prevdata, ...tempdata];
            if (editedByCollaborator === true) {
              visibleCollaborators?.push({
                id: row?.original?.id,
                name: row?.original?.created_by_name,
                email: row?.original?.created_by_email,
                profileImage: row?.original?.created_by_profile_image || '',
                lastEdited: row?.original?.last_edited_by,
                collaboratorsExists: row?.original?.collaborator,
              });
            }
            if (
              visibleCollaborators?.length === 0 &&
              row?.original?.type !== 'Folder'
            ) {
              visibleCollaborators?.push({
                id: row?.original?.id,
                name: row?.original?.created_by_name,
                email: row?.original?.created_by_email,
                profileImage: row?.original?.created_by_profile_image || '',
                lastEdited: row?.original?.last_edited_by,
                collaboratorsExists: row?.original?.collaborator,
              });
            }

            dropdownCollaborators =
              userDataTemp?.length > 0
                ? collaboratorList.slice(3)
                : collaboratorList.slice(4);
            return (
              <div className="collaborators-container">
                {visibleCollaborators?.map((collaborator, index) => (
                  <div
                    key={collaborator.id}
                    className={handleCollaboratorCls(collaborator, index, row)}
                  >
                    {row?.original?.type !== 'Folder' && (
                      <>
                        {collaborator?.profileImage ? (
                          <img
                            src={collaborator?.profileImage}
                            alt="Profile"
                            title={
                              collaborator?.name != null
                                ? `${collaborator?.name} (${collaborator?.email})`
                                : collaborator?.email
                            }
                            className="user-img"
                          />
                        ) : (
                          <span
                            className="user-initials"
                            style={{
                              backgroundColor: index !== 0 && getRandomColor(),
                            }}
                            title={
                              collaborator?.name != null
                                ? `${collaborator?.name} (${collaborator?.email})`
                                : collaborator?.email
                            }
                          >
                            {collaborator?.name
                              ?.split(' ')
                              ?.map(word => word.charAt(0))
                              .join('')}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                ))}

                {collaboratorList?.length > dropdownLength && (
                  <div className="collaborators-dropdown">
                    <div className="dropdown-content">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="dropdown-toggle-collaborators"
                        >
                          <ShowMore
                            onClick={handleShowDropdown}
                            className="show-more"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {dropdownCollaborators?.map((collaborator, index) => (
                            <Dropdown.Item key={index}>
                              <div
                                key={collaborator.id}
                                className={'user-pic-wrapepr'}
                              >
                                {collaborator.collaborator_profile_image ? (
                                  <img
                                    src={
                                      collaborator.collaborator_profile_image
                                    }
                                    alt="Profile"
                                    title={
                                      collaborator.collaborator_name != null
                                        ? `${collaborator?.name} (${collaborator?.email})`
                                        : collaborator?.email
                                    }
                                    className="user-img"
                                  />
                                ) : (
                                  <span
                                    className="user-initials"
                                    style={{
                                      backgroundColor: getRandomColor(),
                                    }}
                                  >
                                    {collaborator.name
                                      ?.split(' ')
                                      ?.map(word => word.charAt(0))
                                      .join('')}
                                  </span>
                                )}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return '--';
          }
        },
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        sticky: 'left',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="folder-actions-wrapper cursorpointer">
            <>
              {row?.original?.type === 'Folder' ? (
                <>
                  <MoveIcon2
                    title="Move"
                    className="move-icon"
                    onClick={() => {
                      setSelectedRows([row?.original]);
                      setShowMovePopup(true);
                    }}
                  />
                  <Edit
                    title="Edit"
                    className="edit-icon-folder"
                    onClick={() => {
                      setShouldCallApi(false);
                      setShowEditFolder(true);
                      setFolderName(row?.original?.full_name);
                      setFolderId(row?.original?.id);
                      setparentId(row?.original?.id);
                    }}
                  />
                  <Delete
                    title="Delete"
                    onClick={() => setFolderSelectedDeldata(row?.original)}
                  />
                </>
              ) : (
                <>
                  <MoveIcon2
                    title="Move"
                    className="move-icon"
                    onClick={() => {
                      setSelectedRows([row?.original]);
                      setShowMovePopup(true);
                    }}
                  />
                  <Share
                    title="Share"
                    onClick={() =>
                      handleShare(row?.original?.id, row?.original)
                    }
                  />

                  {hasBudgetEditAccessInList(row?.original?.my_permission) && (
                    <Edit
                      title="Edit"
                      onClick={() => {
                        setbudgetId(row?.original?.id);
                        setbudgetName(row?.original?.full_name);
                        // setparentId(row?.original?.id);
                        setShowEditBudgetFolder(true);
                        selectTab(row?.original?.id, row?.original);
                        setBudgetType(row?.original?.template_type);
                        // setBudgetToRename(row?.original);
                        // showCreateBudgetModal();
                      }}
                    />
                  )}

                  <Download
                    title="Download"
                    onClick={() => {
                      selectTab(row?.original?.id, row?.original);
                      window.location.href = 'print-budget';
                    }}
                  />

                  <Delete
                    title="Delete"
                    onClick={() => {
                      console.log('row', row);
                      setSelectedDeldata([row?.original]);
                    }}
                  />
                </>
              )}
            </>
          </div>
        ),
      },
    ],
    [selectedRows]
  );

  const handleShare = (id, dataTemp) => {
    selectTab(id, dataTemp);
    showShareBudgetModal();
  };
  const handleShowDropdown = event => {
    setShowDropdown(!showDropdown);
    setAnchorEl(event.currentTarget);
  };

  // Custom sort function for case-insensitive string comparison
  const caseInsensitiveSort = (rowA, rowB, columnId, desc) => {
    const a = rowA.values[columnId]?.toString().toLowerCase();
    const b = rowB.values[columnId]?.toString().toLowerCase();

    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        sortTypes: {
          caseInsensitiveSort,
        },
        initialState: { pageIndex: 0 },
        disableSortRemove: true,
      },
      useGlobalFilter,
      useSortBy,
      useRowSelect
    );

  const handleResultClick = budget => () => {
    // selectTab(budget?.id, budget);
    setFilteredBudgets([]);
  };

  return (
    <>
      <div className="budget-details-wrapper mt-3">
        <div className="details-header-wrapper">
          <div className="details-left d-flex align-items-center">
            <div className="detail-header">My Budgets</div>
            <div className="app-header-search" ref={searchBarRef}>
              <BudgetSearchInput
                onChange={handleSearchChange}
                onFocus={handleSearchChange}
                onKeyPress={handleKeyPress}
                handleClearSearch={handleClearSearch}
                searchVal={searchTxt}
              />
              {/* {filteredBudgets?.length > 0 && (
                <div className="app-header__search__result">
                  {filteredBudgets?.map(budget => (
                    <div
                      key={`result-${budget.id}`}
                      className="result-item"
                      onClick={handleResultClick(budget)}
                    >
                      {budget?.name}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
            <div className="form-check">
              <input
                className="form-check-input adv-chk"
                type="checkbox"
                id="check1"
                name="option1"
                value="something"
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label advanced-srch">
                Advanced Search
              </label>
            </div>
          </div>
          <div className="details-right d-flex align-items-center">
            <button
              label="New Folder"
              type="button"
              className="new-folder"
              // onClick={showCreateFolderModal}
              onClick={() => {
                setShowAddFolder(true);
              }}
            >
              <NewFolder />
              New Folder
            </button>
            <button
              label="New Budget"
              type="button"
              className="new-folder"
              onClick={() => setShowBudgetFolder(true)}
            >
              <NewBudget />
              New Budget
            </button>
          </div>
        </div>
        {loading ? (
          <GridLoader />
        ) : (
          <>
            {parentNames?.length > 0 && (
              <div className="folders-path">
                <div
                  className="bud-hed cursorpointer"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  My Budgets
                </div>
                <GreaterArrow />
                {/* <div className="arrow">&gt;</div> */}
                <>
                  {parentNames?.map((item, index) => (
                    <div className="folder-data" key={item}>
                      <FolderIcon />
                      <div
                        className="bud-head cursorpointer"
                        onClick={() => {
                          if (item?.parent_id === null) {
                            window?.location?.reload();
                          } else {
                            setparentId(item?.parent_id);
                            const indexOfParent15 = parentNames.findIndex(
                              parentData =>
                                parentData?.parent_id === item?.parent_id
                            );
                            const newArray =
                              indexOfParent15 !== -1
                                ? parentNames.slice(0, indexOfParent15 + 1)
                                : parentNames;
                            setparentNames(newArray);
                            setShouldCallApi(true);
                          }
                        }}
                      >
                        {item?.name}
                      </div>
                      {index < parentNames.length - 1 && <GreaterArrow />}
                    </div>
                  ))}
                </>
              </div>
            )}
            {selectedRows?.length > 0 && (
              <div className="selected-records-wrapper">
                <div className="sel-rec-item-count">
                  <CrossIcon onClick={() => setSelectedRows([])} />
                  {selectedRows?.length}{' '}
                  <>{selectedRows?.length === 1 ? 'item' : 'items'}</> selected
                </div>
                <div className="actions-wrapper">
                  <MoveIcon
                    title="Move"
                    className="move-icon"
                    onClick={() => {
                      setShowMovePopup(true);
                    }}
                  />
                  {selectedRows?.length <= 4 &&
                    selectedRows?.length > 0 &&
                    !selectedRows?.some(row => row?.type === 'Folder') && (
                      <OpenInNew
                        title="Open Tab"
                        onClick={() => {
                          selectTab(selectedRows[0]?.id, selectedRows[0]);
                          handleCreateTabs(selectedRows);
                        }}
                      />
                    )}
                  {selectedRows?.length === 1 &&
                    !selectedRows?.some(row => row?.type === 'Folder') && (
                      <Download
                        title="Download"
                        onClick={() => {
                          selectTab(selectedRows[0]?.id, selectedRows[0]);
                          window.location.href = 'print-budget';
                        }}
                      />
                    )}
                  <Delete
                    title="Delete"
                    onClick={() => setSelectedDeldata(selectedRows)}
                  />
                </div>
              </div>
            )}
            <>
              <div className="admin-users-body">
                <>
                  <div
                    className={
                      selectedRows?.length > 0
                        ? 'table-container table-new-height mt-3'
                        : 'table-container mt-3'
                    }
                  >
                    <table {...getTableProps()} className="table-cls w-100">
                      <thead className="app-users-table-head">
                        {headerGroups?.map(headerGroup => (
                          <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={headerGroup}
                          >
                            {headerGroup.headers?.map(column => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                key={column?.id}
                              >
                                {column.render('Header')}
                                <span>
                                  &nbsp;
                                  {column.disableSortBy === false ? (
                                    column?.isSorted ? (
                                      <span className="sorted-icon-highlight">
                                        <HighlightedSelector />
                                      </span>
                                    ) : (
                                      <Selector />
                                    )
                                  ) : null}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <>
                        {rows && products?.length > 0 ? (
                          <tbody
                            {...getTableBodyProps()}
                            className="table-scroll"
                          >
                            {rows && rows?.length > 0 && (
                              <>
                                {rows?.map(row => {
                                  prepareRow(row);
                                  const isSelected = selectedRows.some(
                                    selectedRow =>
                                      selectedRow.id === row.original.id
                                  );
                                  return (
                                    <tr
                                      {...row?.getRowProps()}
                                      className={`table-body-row ${
                                        isSelected ? 'selected-row' : ''
                                      }`}
                                      key={row?.id}
                                    >
                                      <>
                                        {row?.cells?.map((cell, cellIndex) => {
                                          return (
                                            <td
                                              {...cell?.getCellProps()}
                                              key={cellIndex}
                                              style={{
                                                width:
                                                  cellIndex === 0
                                                    ? '40%'
                                                    : 'auto',
                                              }}
                                            >
                                              {cell.render('Cell')}
                                            </td>
                                          );
                                        })}
                                      </>
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        ) : (
                          <>
                            {searchQuery?.length > 0 ? (
                              <div className="no-budget-found">
                                <div className="no-found-head">
                                  {/* Lets Get Started! */}
                                </div>
                                <div className="no-found-res-body">
                                  No results found
                                </div>
                                {/* <button
                                    label="New Budget"
                                    type="button"
                                    className="new-folder"
                                    // onClick={showCreateBudgetModal}
                                    onClick={() => setShowBudgetFolder(true)}
                                  >
                                    New Budget
                                  </button> */}
                              </div>
                            ) : (
                              <div className="no-budget-found">
                                <div className="no-found-head">
                                  Lets Get Started!
                                </div>
                                <div className="no-found-body">
                                  {/* You don't have any existing budgets at the
                                  moment. How would you like to get started? */}
                                  Folder Empty "Create a New Budget in this
                                  Folder"
                                </div>
                                <button
                                  label="New Budget"
                                  type="button"
                                  className="new-folder"
                                  // onClick={showCreateBudgetModal}
                                  onClick={() => setShowBudgetFolder(true)}
                                >
                                  New Budget
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    </table>
                  </div>
                </>
              </div>
            </>
          </>
        )}
      </div>

      {showDeleteMultiBudgets && <DeleteMultiBudgets refetch={refetch} />}
      {showDeleteFolder && <DeleteFolder refetch={refetch} />}
      {showAddFolder && (
        <AddFolder
          parentid={parentId}
          showAddFolder={showAddFolder}
          closeFolder={closeFolder}
          refetch={refetch}
        />
      )}
      {showMovePopup && (
        <MoveBudgets
          showMovePopup={showMovePopup}
          onMoveClose={handleMovePopupClose}
          selBudgets={selectedRows}
          refetch={refetch}
        />
      )}
      {showEditFolder && (
        <EditFolder
          refetch={refetch}
          parentid={parentId}
          showEditFolder={showEditFolder}
          closeFolder={closeFolder}
          folderName={folderName}
          folderId={folderId}
        />
      )}

      {showBudgetFolder && (
        <CreateBudgetInsideFolder
          parentid={parentId}
          showBudgetFolder={showBudgetFolder}
          closeFolder={closeFolder}
          refetch={refetch}
        />
      )}
      {showEditBudgetFolder && (
        <EditBudgetInsideFolder
          budgetName={budgetName}
          parentid={parentId}
          budgetId={budgetId}
          showBudgetFolder={showEditBudgetFolder}
          id={parentId}
          BudgetTypeData={BudgetTypeData}
          closeFolder={closeFolder}
          refetch={refetch}
        />
      )}
    </>
  );
};
BudgetDetails.propTypes = {
  budgetList: PropTypes.array,
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.number,
      full_name: PropTypes.string,
      // ... add other properties as needed
    }),
  }),
};
// BudgetDetails.defaultProps = {
//   budgetList: [],
// };
export default BudgetDetails;
