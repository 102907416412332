import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';
import Login from 'pages/AuthScreens/Login';
import Signup from 'pages/AuthScreens/Signup';
import ForgotPassword from 'pages/AuthScreens/ForgotPassword';
import HomeScreenLayout from 'layouts/HomeScreenLayout';
import BudgetScreen from 'pages/HomeScreens/BudgetScreen';
import BudgetDetails from 'pages/BudgetDetails/index';
import ErrorPage from 'pages/ErrorScreens';
import ALL_ROUTES from 'config/routes';
import AccountSettingsLayout from 'layouts/AccountSettingsLayout';
import Profile from 'pages/HomeScreens/AccountSettings/Profile';
import Payments from 'pages/HomeScreens/AccountSettings/Payments';
import Subscription from 'pages/HomeScreens/AccountSettings/Subscription';
import Notifications from 'pages/HomeScreens/AccountSettings/Notifications';
import ChangePassword from 'pages/HomeScreens/AccountSettings/ChangePassword';
import ContactUs from 'pages/HomeScreens/AccountSettings/ContactUs';
import InviteFriends from 'pages/HomeScreens/AccountSettings/InviteFriends';
import SupportAndFeedback from 'pages/HomeScreens/AccountSettings/SupportAndFeedback';
import PrivacyPolicy from 'pages/HomeScreens/AccountSettings/PrivacyPolicy';
import TermsAndConditions from 'pages/HomeScreens/AccountSettings/TermsAndConditions';
import Faqs from 'pages/HomeScreens/AccountSettings/Faqs';
import { AppContextProvider } from 'contexts/AppContext';
import { HomeScreenContextProvider } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import PrintBudget from 'pages/HomeScreens/BudgetScreen/PrintBudget';
import FringesDetails from 'pages/FringesDetails/index';

const router = createBrowserRouter([
  {
    path: ALL_ROUTES.HOME,
    element: (
      <AppContextProvider>
        <Outlet />
      </AppContextProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={ALL_ROUTES.HOME_SCREEN} replace={true} />,
      },
      {
        path: ALL_ROUTES.AUTH,
        element: <AuthLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Login />,
          },
          {
            path: ALL_ROUTES.AUTH_CHILDREN.LOGIN,
            element: <Login />,
          },
          {
            path: ALL_ROUTES.AUTH_CHILDREN.SIGNUP,
            element: <Signup />,
          },
          {
            path: ALL_ROUTES.AUTH_CHILDREN.FORGOT_PASSWORD,
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: ALL_ROUTES.HOME_SCREEN,
        element: (
          <HomeScreenContextProvider>
            <HomeScreenLayout />
          </HomeScreenContextProvider>
        ),
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            path: ALL_ROUTES.BUDGET_DETAILS,
            element: <BudgetDetails />,
          },
          {
            index: true,
            element: <BudgetScreen />,
          },
          {
            path: ALL_ROUTES.HOME_SCREEN_CHILDREN.PRINT_BUDGET,
            element: <PrintBudget />,
          },
          {
            path: ALL_ROUTES.FRINGES_DETAILS,
            element: <FringesDetails />,
          },
          {
            path: ALL_ROUTES.HOME_SCREEN_CHILDREN.ACCOUNT_SETTINGS,
            element: <AccountSettingsLayout />,
            children: [
              {
                index: true,
                element: (
                  <Navigate
                    to={ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PROFILE}
                    replace={true}
                  />
                ),
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PROFILE,
                element: <Profile />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PAYMENTS,
                element: <Payments />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.SUBSCRIPTION,
                element: <Subscription />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.NOTIFICATIONS,
                element: <Notifications />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.CHANGE_PASSWORD,
                element: <ChangePassword />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.CONTACT_US,
                element: <ContactUs />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.INVITE_FRIENDS,
                element: <InviteFriends />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.SUPPORT_FEEDBACK,
                element: <SupportAndFeedback />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PRIVACY_POLICY,
                element: <PrivacyPolicy />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.TERMS_CONDITIONS,
                element: <TermsAndConditions />,
              },
              {
                path: ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.FAQS,
                element: <Faqs />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
