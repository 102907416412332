import FringesDetails from './Fringedetails';
import GroupFringes from './GroupFringes';
import './styles.scss';
import ToggleButtons from '../../components/SharedComponents/ToggleButtons';
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { useAppContext } from 'contexts/AppContext';
import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow-back.svg';
import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/images/arrow-forward.svg';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import Chip from 'components/Chip';
import { AnimatePresence, motion } from 'framer-motion';

const FringesPage = () => {
  const searchBarRef = useRef();
  const contextualSuggestionsRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const { createTabsMutation } = useHomeScreenContext();
  const { selectedBudget, contextualSuggestions, addedContextualSuggestions } =
    useHomeScreenContext();
  const [view, setView] = useState('fringes'); // State to toggle views
  const { userProfile } = useAppContext();
  const queryClient = useQueryClient();
  const swimlaneData = queryClient.getQueryData([
    'get-swimlanes-in-budget',
    { id: selectedBudget?.budget, type: 'swimlane' },
  ]);
  const swimlaneItems = swimlaneData?.data;
  const [fringes, setFringes] = useState([]); // State for fringes

  // Fetch fringes data
  const fetchFringes = async () => {
    try {
      const storedToken = JSON.parse(localStorage.getItem('authToken'));
      const response = await axios.get('/fringe/fringe/', {
        headers: {
          Authorization: `Token ${storedToken?.token}`,
        },
      });
      setFringes(response.data?.data);
      console.log('Fetched fringes:', response.data?.data);
    } catch (error) {
      console.error('Error fetching fringes:', error);
    }
  };

  useEffect(() => {
    fetchFringes();
  }, []);

  const handleBackClick = () => {
    if (scrollPosition > 0) {
      const position = scrollPosition - 350;
      setScrollPosition(scrollPosition < 0 ? 0 : position);
    }
  };

  const handleFrowardClick = () => {
    const scrollWidth = contextualSuggestionsRef?.current?.scrollWidth;
    if (scrollPosition < scrollWidth) {
      const position = scrollPosition + 350;
      setScrollPosition(scrollPosition > scrollWidth ? scrollWidth : position);
    }
  };
  const renderContextualSuggestions = () => {
    const suggestions = contextualSuggestions?.filter(suggestionItem => {
      return !(
        addedContextualSuggestions[suggestionItem?.name] ||
        swimlaneItems?.some(
          item =>
            item?.name?.toLowerCase() === suggestionItem?.name?.toLowerCase()
        )
      );
    });
    return (
      <div className="contextual-suggestions">
        <div className="contextual-suggestions__title">Suggestions:</div>
        <div className="contextual-suggestions__container">
          <ArrowBackIcon
            className={`back-icon ${scrollPosition > 0 ? '' : 'disabled'}`}
            onClick={handleBackClick}
          />
          <div
            className="contextual-suggestions__container__chip-wrapper"
            ref={contextualSuggestionsRef}
          >
            {suggestions?.map(item => {
              return (
                <Chip
                  key={item?.id}
                  className="suggestions-chip"
                  title={item?.name}
                  icon={<AddIcon />}
                  // onClick={handleContextualSuggestionClick(item)}
                  // disabled={!hasEditAccess()}
                />
              );
            })}
          </div>
          <ArrowForwardIcon
            className={`forward-icon ${
              scrollPosition < contextualSuggestionsRef?.current?.scrollWidth
                ? ''
                : 'disabled'
            }`}
            onClick={handleFrowardClick}
          />
        </div>
      </div>
    );
  };
  const renderTotal = () => {
    // const lastEditedBy =
    //   budgetData?.last_edited_by || budgetData?.created_by_name;
    // const lastEditDateTime = budgetData?.updated_on || budgetData?.created_on;
    return (
      <div className="budget-total-wrapper-fringe">
        <Chip className="budget-total" title={`Fringe Total: $0.00`} />
        <div className="budget-modified">
          {/* <span className="budget-modified-by"></span> on{' '} */}
          {/* {formatBudgetScreenDateTime(lastEditDateTime)} */}
        </div>
      </div>
    );
  };
  const handleFringesDetailsCallback = () => {
    console.log('Callback invoked in FringesPage!');
    fetchFringes();
  };

  return (
    <div>
      <ToggleButtons
        options={['Fringes', 'Groups']}
        clasname="fringes-groups"
        active={view}
        onToggle={setView}
      />

      <AnimatePresence mode="wait">
        {view === 'fringes' && (
          <motion.div
            key="fringes"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FringesDetails
              onFringeCreated={handleFringesDetailsCallback}
              className="fringe"
            />
            {!!fringes.length && (
              <div className="contextual-suggestions-container">
                {renderContextualSuggestions()}
                {renderTotal()}
              </div>
            )}
          </motion.div>
        )}

        {view === 'groups' && (
          <motion.div
            key="groups"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <GroupFringes className="groups" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FringesPage;
